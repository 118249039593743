.page {
  &__entry {
    p,
    ul,
    ol {
      margin-bottom: 1.8rem;
      font-size: 1.1rem;
      line-height: 1.65;
      @media screen and (max-width: 575px) {
        margin-bottom: 1.2rem;
        font-size: 1rem;
        line-height: 1.65;
      }
    }
    p {
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $typo-principal;
      font-weight: bold;
      margin-bottom: 1.8rem;
      @media screen and (max-width: 575px) {
        margin-bottom: 1rem;
      }
    }
    .wp-block-image {
      margin-top: 1.8rem !important;
      margin-bottom: 1.8rem !important;
    }
    h2 {
      color: $color-secundario-dark;
    }
    h3 {
      font-size: 1.4rem;
      color: $color-secundario-dark;
    }
  }
}

.page-id-1033 .page__entry {
  h2 {
    color: $color-secundario-dark;
  }
  h3 {
    color: $color-secundario-dark;
  }
}
