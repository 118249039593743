.estetica-cirugia {
  &__content {
    .titulos {
      max-width: 55%;
      @media screen and (max-width: 991px) {
        max-width: 100%;
      }
    }
  }
  &__titulo {
  }
  &__subtitulo {
  }
  &__servicios {
  }

  &__tipo {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10rem;
    @media screen and (max-width: 991px) {
      gap: 1rem;
      grid-template-columns: 1fr;
    }
  }

  &__left {
    @media screen and (max-width: 991px) {
      order: 2;
    }
  }
  &__right {
    @media screen and (max-width: 991px) {
      order: 1;
    }
  }

  &__titulo {
  }

  &__subtitulo {
    font-family: "karmina-sans", sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
  }

  &__tratamientos {
    display: flex;
    flex-wrap: wrap;
  }

  &__tratamiento {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    font-size: 1rem;
    border: 1px solid $color-principal;
    background-color: $color-principal;
    color: $color-texto;
    padding: 0.6rem 1.5rem;
    border-radius: 0rem;
    box-shadow: $box-shadow-02;
    a {
      color: $color-texto;
      text-decoration: none;
    }
    @media screen and (max-width: 575px) {
      padding: 0.2rem 0.5rem;
      font-size: 0.9rem;
    }
  }

  &__thumb {
  }

  &__img {
    width: 100%;
    height: auto;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
  }
}

.estetica-cirugia__tipo:nth-child(1) {
  margin-top: 0;
}

.estetica-cirugia__tipo:nth-child(even) {
  .estetica-cirugia__left {
    order: 2;
  }
  .estetica-cirugia__right {
    order: 1;
    @media screen and (max-width: 991px) {
      order: 1;
    }
  }
}
