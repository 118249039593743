.contacto {
  &__content {
    display: grid;
    gap: 3rem;
    grid-template-columns: 1fr 1.5fr;

    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
    }

    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }

  &__form-priv {
    font-size: 0.7rem;
  }
  &__col1 {
  }
  &__col2 {
    @media screen and (max-width: 991px) {
      margin: 2rem 0;
    }
    @media screen and (max-width: 575px) {
      margin: 3rem 0 1rem 0;
    }
  }

  &__form {
    &--margen {
      margin-bottom: 1rem;
    }
    &--margenMov {
      @media screen and (max-width: 575px) {
        margin-bottom: 1rem;
      }
    }
    a {
      color: $color-principal-dark;
      text-decoration: none;
      &:hover {
        color: darken($color-principal-dark, 5%);
      }
    }
  }

  &__boton {
    max-width: fit-content;
  }

  &__col3 {
    grid-column: 1 / span 2;
    @media screen and (max-width: 575px) {
      margin-bottom: 2rem;
    }
  }

  &__titulo {
    font-family: $typo-principal;
    color: $color-alternativo;
    text-transform: uppercase;
    font-size: 1.2rem;
    margin-bottom: 0;

    font-weight: bold;
    @media screen and (max-width: 575px) {
      align-self: center;
      margin-bottom: 0;
    }
  }

  &__subtitulo {
    margin-bottom: 0.5rem;
  }

  &__datos {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 991px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 3rem;
      justify-content: center;
      text-align: center;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }

  &__dato {
    margin-bottom: 2rem;
    @media screen and (max-width: 991px) {
      margin-bottom: 0;
    }
    @media screen and (max-width: 575px) {
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      gap: 1rem;
    }
  }

  &__telefonos {
    .contacto__info {
      a {
        display: block;
      }
    }
  }

  &__info {
    @media screen and (max-width: 575px) {
      align-self: center;
      p {
        margin-bottom: 0;
      }
    }
  }

  .page__entry {
    margin-top: 10px;
    margin-bottom: 3rem;
    font-size: 1.4375rem;
  }
}

.contactos {
  background-color: $color-secundario;

  &__container {
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6rem;
    h4 {
      color: $color-secundario-dark;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem 1rem;
    }
  }

  &__col {
  }

  .boton {
    margin-bottom: 0.5rem;
    width: fit-content;
  }

  &__grupo {
    display: flex;
    .bi {
      margin-right: 0.3rem;
    }
  }

  .contacto__dato {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  &__col1 {
  }

  &__col2 {
  }

  &__col3 {
  }
}

.contactos__col:nth-child(3) {
  @media screen and (max-width: 575px) {
    grid-column: 1 / span 2;
  }
}

.mapa {
  iframe {
    height: 500px;
    width: 100%;
  }

  &__titulo {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  &__numeros {
    ul {
      list-style: none;
      padding-left: 0px;
    }
    li {
      span {
        font-weight: bold;
        display: inline-block;
        width: 120px;
      }
    }
  }
  &__mail {
    span {
      font-weight: bold;
      display: inline-block;
      width: 120px;
    }
  }
  &__direccion {
    span {
      font-weight: bold;
      display: inline-block;
      width: 120px;
    }
  }
}
