.pro-claim {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 4rem;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  &__logo {
    max-width: 300px;
    height: auto;
    margin-bottom: 2rem;
  }
  &__silla {
    img {
      width: 100%;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 50%;
      }
    }
  }

  &__descripcion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
      font-weight: bold;
      margin-bottom: 1.5rem;
    }
  }
}

.pro-descripcion {
  text-align: center;
  font-family: $typo-secundaria;
  font-weight: bold;
  color: $color-principal-dark;
  span {
    font-weight: normal;
    font-family: $typo-principal;
    display: block;
    color: $color-gris-dark;
    margin-top: 2rem;
    font-size: 80%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 991px) {
      width: 100%;
      margin-top: 1rem;
    }
  }
  @media screen and (max-width: 575px) {
    font-size: 1.5rem;
  }
}

.pro-tratamientos {
  &__item {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    gap: 4rem;
    margin-bottom: 5rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
    @media screen and (max-width: 767px) {
      gap: 1rem;
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &__content {
  }

  &__imagen {
    img {
      width: 100%;
      height: auto;
    }
  }

  &__titulo {
    margin-bottom: 1.5rem;
    color: $color-secundario-dark;
    font-weight: bold;
    font-size: 2.7rem;
  }

  &__descripcion {
    h3 {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
}

.faq {
  &__logo {
    margin-bottom: 4rem;
    text-align: center;
    @media screen and (max-width: 991px) {
      text-align: left;
    }
    img {
      width: 250px;
      height: auto;
    }
  }
  &__content {
    color: $color-background;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 4rem;
    margin-bottom: 4rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }

  &__titulo {
    text-align: right;
    @media screen and (max-width: 991px) {
      text-align: left;
    }
  }

  &__descripcion {
  }
}
