.navbar {
  .ubermenu .ubermenu-item.ubermenu-active > .ubermenu-submenu-drop,
  .ubermenu-nojs .ubermenu-item:hover > .ubermenu-submenu-drop {
    display: flex;
  }

  .ubermenu
    .ubermenu-item:nth-last-child(1)
    .ubermenu-submenu-drop.ubermenu-submenu-align-full_width {
    right: 0;
  }
  .ubermenu .ubermenu-submenu-drop.ubermenu-submenu-align-full_width {
    width: auto !important;
    background-color: rgba($color-blanco, $alpha: 1);
    position: absolute;
    top: 100%;
    margin-top: 20px;
    left: auto;
    display: flex;
    flex-direction: column;
    .ubermenu-target {
      color: $color-texto;
      &:hover {
        background-color: $color-texto;
        color: $color-blanco;
      }
    }
  }
}

.ubermenu {
  margin-left: auto !important;
  &-nav {
    @media screen and (max-width: 991px) {
      display: none !important;
    }
  }
  &-target {
    color: $color-texto;
    font-size: 1.1rem;
    font-weight: 700 !important;
    padding: 11px 16px !important;
    // text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    &:hover {
      color: $color-texto;
    }
  }
  &-sub-indicator {
    //display: none;
  }
}

// .ubermenu .ubermenu-active .ubermenu-target {
//   color: $color-texto;
// }

// .ubermenu .ubermenu-submenu .ubermenu-item:nth-last-child(1) .ubermenu-target {
//   margin-left: 0;
// }

.page-template-front-page .ubermenu .ubermenu-target,
.page-template-front-page button.ubermenu-responsive-toggle-align-full,
.page-template-front-page .header__burger a,
.page-template-front-page .header__right a {
  color: $color-blanco;
}

.ubermenu .ubermenu-item:nth-last-child(1) .ubermenu-target {
  margin-right: 0;
  background-color: $color-blanco;
  color: $color-texto;
  margin-left: 1.4rem;
  border-radius: 2px;
}

.ubermenu .ubermenu-submenu .ubermenu-item .ubermenu-target {
  margin-right: 0;
  margin-left: 0;
  background-color: transparent;
}

.header--scroll {
  .ubermenu .ubermenu-target,
  button.ubermenu-responsive-toggle-align-full,
  .header__burger a {
    color: $color-blanco;
    transition: $trans-3;
  }
}

.navbar-brand {
}

.nav-link {
}

.header .nav-link {
  font-size: 0.85rem;
}

#navbarSupportedContent {
  justify-content: flex-end;
}

.categorias-home {
  background-color: $color-background;
  padding: 0.3rem 1.5rem 0rem;
  &--home {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
  &--shop {
    ul.product-categories {
      padding-top: 0 !important;
    }
  }
}

.single-product .categorias-home {
  display: none;
}
.categorias-header {
  &__title {
    display: none;
  }
  ul.product-categories {
    margin-left: -1.1rem;
    margin-right: -1.1rem;
    padding-left: 1rem;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 1rem;
    padding-top: 1rem;
    @media screen and (min-width: 768px) {
      flex-wrap: wrap;
      margin-left: 0rem;
      margin-right: 0rem;
    }
    @media screen and (max-width: 767px) {
      justify-content: flex-start;
    }
    li.current-cat {
      a {
        background-color: $color-gris-02 !important;
      }
    }
    li.cat-item {
      margin: 0 5px;
      @media screen and (min-width: 768px) {
        margin: 0 5px 5px 0;
      }
      @media screen and (max-width: 767px) {
        margin: 0 0 0 8px;
      }
      &:first-child {
        @media screen and (max-width: 767px) {
          margin-left: 0 !important;
        }
      }
      a {
        padding: 9px 1rem 9px;
        color: $color-background;
        background-color: $color-gris-04;
        border-radius: 50px;
        font-size: $font-size-small;
        transition: $trans;
        display: inline-flex;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: inherit;
        white-space: nowrap;
        &:hover {
          transition: $trans;
          background-color: $color-principal;
          color: $color-background !important;
        }
        &:focus {
          transition: $trans;
          background-color: $color-gris-04;
          color: $color-background !important;
        }
      }
    }
  }
}

.categorias-home--shop {
  ul.product-categories {
    @media screen and (min-width: 768px) {
      justify-content: flex-start;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 1rem;
  }

  .container {
    @media screen and (max-width: 767px) {
      max-width: 100%;
      padding-left: 0;
      margin-left: 0;
      margin-right: 0;
      padding-right: 0;
    }
  }
}

.single-product .c-list {
  display: none;
}

.c-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 1rem;
  padding-top: 1rem;

  @media screen and (min-width: 768px) {
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
  }

  &--home {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &--shop {
    @media screen and (max-width: 767px) {
      padding-left: 0;
    }
  }

  &__item {
    display: inline-flex;
    margin-right: 5px;
    @media screen and (min-width: 768px) {
      margin-bottom: 5px;
    }
  }
  &__link {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border: 1px solid $color-principal-dark;
    color: $color-gris-03;
    padding: 7px 10px;
    border-radius: 3px;
    font-weight: medium;
    overflow: hidden;
    padding-right: 0.7rem;
    width: auto;
    padding-left: 0.7rem;
    overflow: hidden;
    transition: $trans;
    &:hover {
      background-color: $color-principal-dark;
      color: $color-background;
      transition: $trans;
    }

    @media screen and (max-width: 767px) {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    @media screen and (max-width: 360px) {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    &--active {
      background-color: $color-principal-dark;
      color: $color-background;
      border-color: $color-principal-dark;
      pointer-events: none;
      font-weight: bold;
      &:hover {
        background-color: $color-principal-dark;
        cursor: default;
      }
    }
  }
  &__img {
    width: 65px;
    height: auto;
    border-radius: 20px;
    transform: scale(0.8);
    @media screen and (min-width: 768px) {
      width: 60px;
    }
  }
  &__name {
    // color: $color-background;
    white-space: nowrap;
    &--pic {
      padding-left: 0;
    }
  }
}
