.cta-acf {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__pretitulo {
    font-size: 1.375rem;
    line-height: 1.2;
    font-family: $typo-principal;
    @media screen and (max-width: 575px) {
      font-size: 1rem;
    }
  }

  &__titulo {
    font-size: 3.0625rem;
    line-height: 1.2;
    font-weight: 600;
    @media screen and (max-width: 991px) {
      font-size: 2.8rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 2.2rem;
    }

    margin-bottom: 2rem;
  }

  &__botones {
  }

  &__boton.me-2 {
    @media screen and (max-width: 575px) {
      margin: 0 0 0.5rem 0 !important;
    }
  }
  &__boton.ms-2 {
    @media screen and (max-width: 575px) {
      margin: 0 0 0 0 !important;
    }
  }
}
