.header {
  z-index: 1000;
  position: fixed;
  width: 100%;
  color: $color-blanco;
  transition: background-color 0.5s linear, top 0.5s ease-in-out;
  top: 0;
  z-index: 30;
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    width: 100%;
  }

  &__logo {
    display: inline-block;
    height: 32px;
    width: auto;
    transition: $trans-3;
    @media screen and (max-width: 575px) {
      width: auto;
      height: 28px;
      transition: $trans;
    }
  }

  &__navbar {
    padding: 3rem 0;
    display: flex;
    justify-content: flex-start;
    transition: $trans-3;
    @media screen and (max-width: 575px) {
      padding: 1rem 0;
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 2rem;
    @media screen and (max-width: 991px) {
      margin-left: 0;
      margin-right: 2rem;
    }
    @media screen and (max-width: 767px) {
      margin-right: 1.5rem;
    }
    .bi {
      font-size: 1.8rem;
      @media screen and (min-width: 1199px) {
        font-size: 1.5rem;
      }
    }
  }

  &__burger {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    a {
      color: $color-texto;
      i {
        font-size: 2.5rem;
        transition: $trans;
      }
      &:hover {
        color: $color-gris-02;
      }
    }
    @media screen and (min-width: 992px) {
      display: none;
    }
    @media screen and (max-width: 991px) {
      display: inline-block;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.5rem;
    }
  }
}

.header--sticky {
  background-color: transparent;
  box-shadow: none;
  padding: 1rem 0;
  transition: $trans-3;
  z-index: 30;
}
.header--scroll {
  background-color: rgba($color: #000000, $alpha: 0.9);
  box-shadow: $box-shadow;
  transition: $trans-3;
  z-index: 30;
  .header__navbar {
    padding: 1rem 0;
    transition: $trans-3;
    @media screen and (max-width: 575px) {
      padding: 0.5rem 0;
      transition: $trans-3;
    }
  }
  .header__right {
    a {
      color: $color-blanco;
      transition: $trans;
    }
  }

  .header__burger i {
    @media screen and (max-width: 575px) {
      transition: $trans-3;
      font-size: 2rem;
    }
  }
}

.header--scroll .header__logo {
  transition: $trans-3;
  filter: brightness(100);
  height: 24px;
  @media screen and (max-width: 575px) {
    height: 22px;
  }
}

.logotipo {
}

.page-template-front-page .header__logo {
  filter: brightness(100);
}

.menu-overlay {
  width: 100%;
  height: 100vh;
  background-color: $color-blanco;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  z-index: 40;
  list-style: none;

  &--oculto {
    display: none;
    transition: $trans;
  }
  &--activo {
    display: flex;
    flex-direction: column;
    transition: $trans;
    z-index: 1000;
  }
  &__navbar {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
    @media screen and (max-width: 575px) {
      margin-top: 0;
      padding-top: 0;
    }
  }
  &__cerrar {
    z-index: 2;
    position: relative;
    font-size: 3rem;
    line-height: 0;
    padding-right: 3rem;
    padding-top: 3.1rem;
    color: $color-principal-dark;
    text-align: right;
    @media screen and (max-width: 575px) {
      padding-right: 1.5rem;
      padding-top: 1.2rem;
    }
    a {
      color: $color-principal-dark;
      &:hover {
        color: rgba($color-principal-dark, $alpha: 0.7);
      }
    }
    @media screen and (max-width: 575px) {
      font-size: 3rem;
    }
  }
  &__menu {
    width: 100%;
    padding: 0 1.5rem;
  }
  &__nav {
    text-align: flex-start;
    padding-left: 0;
    list-style: none;
    font-size: 1rem;
    li.menu-item {
      margin-bottom: 0.7rem;
      & > a {
        font-size: 1.2rem;
        font-weight: bold;
      }
      ul.sub-menu {
        list-style: none;
        display: flex;
        justify-content: flex-start;
        padding-left: 0;
        flex-wrap: wrap;
        li {
          margin: 0 5px 5px 0px;
          a {
            background-color: $color-alternativo;
            color: $color-gris-01 !important;
            font-size: 1rem;
            padding: 4px 8px;
            border-radius: 4px;
            display: inline-block;
            font-weight: 700;
            &:hover {
              color: $color-principal-dark !important;
            }
          }
        }
      }
    }
    .menu-item-1848 {
      display: none;
    }
  }

  &__botones {
    width: 100%;
    padding: 0 1.5rem;
  }

  &__boton {
    display: block;
    width: 100%;
    padding: 4px 8px !important;
    text-align: center;
  }
}

.menu-overlay__boton:nth-last-child(1) {
  margin-top: 1rem;
}
