.hero {
  position: relative;
  height: 640px;
  background: url("../../assets/site/bg-hero-03.jpg") no-repeat center center;
  background-size: cover;
  z-index: 1;
  @media screen and (max-width: 991px) {
    //background-position: 79% center;
  }
  @media screen and (max-width: 767px) {
    //background-position: 88% center;
    height: 430px;
    // background-size: contain;
    // background-color: rgba($color-alternativo, $alpha: 1);
  }

  &__overlay {
    // display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, #00deff, #a7d714);
    z-index: 2;
    mix-blend-mode: multiply;
  }

  &__overlay-2 {
    z-index: 3;
    // display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: url("../../assets/site/bg-groovepaper.png") center center;
    mix-blend-mode: multiply;
  }

  &__container {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 4;
    @media screen and (max-width: 575px) {
      align-items: flex-end;
      padding-bottom: 1.5rem;
    }
  }

  &__content {
    padding-top: 3rem;
    position: relative;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @media screen and (max-width: 991px) {
      width: 60%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__header {
    width: 100%;
  }

  &__logo {
    width: 70px;
    height: auto;
    margin-bottom: 2rem;
    @media screen and (max-width: 767px) {
      width: 40px;
      margin-bottom: 1rem;
    }
  }

  &__title {
    font-size: 4.0625rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    @media screen and (max-width: 991px) {
      font-size: 3rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 2rem;
    }
  }

  &__subtitle {
    font-family: $typo-principal;
    font-size: 1.375rem;
    font-weight: 400;
    margin-bottom: 2rem;
    color: #fff;
    font-weight: 500;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    @media screen and (max-width: 575px) {
      font-size: 1rem;
      margin-bottom: 0.8rem;
    }
  }

  &__footer {
    @media screen and (max-width: 991px) {
      display: block;
      .boton {
        margin: 0.5rem 0.5rem 0.5rem 0 !important;
      }
    }
  }
}
