.footer {
  background-color: $color-gris-01;
  color: $color-blanco;
  padding-top: 6rem;
  @media screen and (max-width: 575px) {
    padding-top: 3rem;
  }
  &__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 575px) {
      gap: 2rem;
      margin-bottom: 2rem;
    }
  }

  .f-contacto {
    &__datos {
      display: flex;
      flex-direction: column;
    }
    &__item {
      color: $color-blanco;
      text-decoration: none;
      margin-bottom: 5px;
      i {
        color: $color-blanco;
        margin-right: 1rem;
      }
    }
  }

  &__item {
    &--contacto {
    }
    &--direccion {
    }
    &--nosotros {
      @media screen and (max-width: 575px) {
        grid-column: 1 / span 2;
      }
    }
    &--rs {
      .socicon {
        background-color: transparent !important;
        color: $color-alternativo !important;
        font-size: 1.5rem !important;
        &:hover {
          color: lighten($color-alternativo, $amount: 10) !important;
        }
      }
      .socicon-facebook {
        padding-left: 0 !important;
      }
    }
  }

  &__logo {
    max-width: 220px;
    margin-bottom: 3rem;
    @media screen and (max-width: 575px) {
      max-width: 160px;
    }
  }

  &__euro {
    width: 60%;
    .wp-block-media-text {
      display: grid;
      grid-template-columns: 1fr 6fr;
      gap: 1rem;
      color: rgba($color: #ffffff, $alpha: 0.5);
      @media screen and (max-width: 767px) {
        grid-template-columns: 1fr 4fr;
      }
      &__media {
        align-self: flex-start !important;
      }
    }

    .wp-block-media-text.is-stacked-on-mobile {
      @media screen and (max-width: 575px) {
        grid-template-columns: 1fr 2fr !important;
      }
    }
    img {
      width: 100%;
      height: auto;
    }
    .wp-block-media-text__content {
      align-self: center;
      padding: 0 !important;
      font-size: 0.875rem !important;
      @media screen and (max-width: 575px) {
        font-size: 12px !important;
      }
      p {
        margin-bottom: 0;
        -webkit-font-smoothing: antialiased;
        -moz-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -ms-font-smoothing: antialiased;
        -o-font-smoothing: antialiased;
        font-smooth: antialiased;
      }
    }
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
  .widget {
    &__title {
      font-family: $typo-principal;
      color: $color-alternativo;
      font-size: 1.2rem;
      font-weight: 700;
      margin-bottom: 1.5rem;
      @media screen and (max-width: 575px) {
        margin-bottom: 0.75rem;
      }
    }
  }
}

.copyright {
  background-color: $color-gris-01;
  color: $color-background;
  &__content {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      text-align: center;
    }
  }

  &__item {
    color: $color-blanco;
  }

  &__texto {
  }

  &__enlaces {
  }

  &__enlace {
  }

  &__desarrollador {
  }

  &__item {
  }
  a {
    color: $color-alternativo;
    text-decoration: none;
    &:hover {
      color: lighten($color-alternativo, $amount: 10);
    }
  }

  &__social {
    & .social-icon {
      color: $color-gris-03 !important;
      &:hover {
        color: $color-principal !important;
      }
    }
    & .zoom-social_icons-list__item {
      margin-bottom: 0 !important;
    }
  }
}

.compres {
  padding: 2rem 0;
  &__container {
  }

  &__content {
    display: flex;
    justify-content: space-around;
  }

  &__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    @media screen and (max-width: 575px) {
      margin-bottom: 1rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icono {
    @media screen and (max-width: 575px) {
      flex: 0 0 10%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .bi {
      font-size: 2rem;
      color: $color-principal-dark;
    }
  }

  &__titulo {
    font-weight: 500;
    font-size: $font-size-small;
    color: lighten($color-principal-dark, 15);
    span {
      display: block;
      color: $color-principal-dark;
    }
  }
}
