.citas {
  &__content {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 10rem;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }

  &__left {
    @media screen and (max-width: 991px) {
      margin-bottom: 3rem;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      gap: 0;
    }
  }

  &__instruccion {
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 2rem;
    margin-bottom: 2rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 4fr;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr 4fr;
    }
  }

  &__instruccion--1 {
  }

  &__instruccion--2 {
  }

  &__instruccion--3 {
  }

  &__instruccion-numero {
    background-color: $color-principal-dark;
    border-radius: 32px;
    width: 4rem;
    height: 4rem;
    font-size: 3rem;
    font-weight: bold;
    color: $color-blanco;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 991px) {
      justify-self: center;
      align-self: start;
    }
  }

  &__instruccion-descripcion {
    h2 {
      font-weight: bold;
      font-size: 2.185rem;
      margin-bottom: 1rem;
      @media screen and (max-width: 575px) {
        font-size: 1.875rem;
        margin-bottom: 0.75rem;
      }
    }
    h5 {
      font-size: 1.375rem;
      line-height: 1.2;
      font-family: $typo-principal;
      @media screen and (max-width: 575px) {
        font-size: 1.2rem;
      }
    }
  }

  &__right {
    box-shadow: $box-shadow;
    iframe {
      @media screen and (max-width: 575px) {
        height: 770px !important;
      }
    }
  }

  .tt-booking-button-container-customer button.tt-booking-button-customer {
    display: inline-block !important;
    padding: 12px 30px !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 20px !important;
    transition: $trans !important;
    text-decoration: none !important;
    border-radius: 2px !important;
    border: 2px solid !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    -ms-font-smoothing: antialiased !important;
    -o-font-smoothing: antialiased !important;
    font-smooth: antialiased !important;
    background-color: $color-principal-dark !important;
    color: $color-background !important;
    border-color: $color-principal-dark !important;
    width: 100% !important;
    &:hover {
      background-color: lighten(
        $color: $color-principal-dark,
        $amount: 10
      ) !important;
      border-color: lighten(
        $color: $color-principal-dark,
        $amount: 10
      ) !important;
      color: $color-background !important;
    }
    @media screen and (max-width: 991px) {
      padding: 10px 22px !important;
    }
  }

  .tt-booking-button-customer.boton-citas {
    background-color: $color-principal-dark !important;
  }
}

.page-id-13 .citas__instruccion-numero {
  background-color: $color-alternativo !important;
}
