.gblog {
  &__titulo {
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem 2rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: 4rem;
    }
  }

  &__item {
  }

  &__thumb {
    display: block;
    margin-bottom: 1rem;
  }

  &__img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    box-shadow: $box-shadow-02;
  }

  &__time {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  &__tax {
    font-size: $font-size-small;
    text-transform: uppercase;
    a {
      color: $color-secundario-dark;
      font-weight: bold;
    }
  }

  &__titulo {
    font-size: 1.8rem;
    font-weight: bold;
  }

  &__descripcion {
    align-self: center;
  }
}

.blog__item:nth-last-child(1) {
  margin-bottom: 0;
}

.entrada {
  &__content {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    gap: 6rem;
    @media screen and (max-width: 991px) {
      display: flex;
      gap: 0;
      flex-direction: column;
    }
  }

  &__left {
    @media screen and (max-width: 991px) {
      margin-bottom: 2rem;
    }
  }
  &__right {
  }

  &__img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }

  &__titulo {
    margin-top: 0;
    padding-top: 0;
    color: $color-texto;
    font-size: 2.5rem !important;
    font-weight: bold;
    margin-bottom: 2rem;

    @media (max-width: 575px) {
      font-size: 1.8rem !important;
      margin-bottom: 1rem;
    }
  }

  .page__entry {
    font-size: 1.25rem;
  }
}
