.boton {
  display: inline-block;
  padding: 12px 30px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: #fff;
  transition: $trans;
  text-decoration: none;
  border-radius: 2px;
  border: 2px solid;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smooth: antialiased;
  @media screen and (max-width: 991px) {
    padding: 10px 22px;
  }

  &:hover {
    text-decoration: none;
    transition: $trans;
  }

  &:disabled {
    background-color: lighten($color: $color-principal-dark, $amount: 10);

    &:hover {
      background-color: lighten($color: $color-principal-dark, $amount: 10);
    }
  }
}

@media screen and (max-width: 991px) {
  .boton.me-2 {
    margin-right: 0.2rem !important;
  }
  .boton.ms-2 {
    margin-left: 0.2rem !important;
  }
}

.boton--principal {
  background-color: $color-principal-dark;
  color: $color-background;
  border-color: $color-principal-dark;
  &:hover {
    background-color: lighten($color: $color-principal-dark, $amount: 10);
    border-color: lighten($color: $color-principal-dark, $amount: 10);
    color: $color-background;
  }
}
.boton--secundario {
  background-color: $color-secundario-dark;
  color: $color-background;
  border-color: $color-secundario-dark;
  &:hover {
    background-color: lighten($color: $color-secundario-dark, $amount: 10);
    border-color: lighten($color: $color-secundario-dark, $amount: 10);
    color: $color-background;
  }
}
.boton--blanco {
  background-color: $color-blanco;
  color: $color-texto;
  border-color: $color-blanco;
  &:hover {
    background-color: darken($color: $color-blanco, $amount: 10);
    color: $color-texto;
    border-color: darken($color: $color-blanco, $amount: 10);
  }
}

.boton--transparent {
  background-color: transparent;
  color: $color-texto;
  border-color: $color-texto;
  &:hover {
    background-color: transparent;
    color: lighten($color: $color-texto, $amount: 10);
    border-color: lighten($color: $color-texto, $amount: 10);
  }
}

.boton--transparent-hero {
  background-color: transparent;
  color: $color-blanco;
  border-color: $color-blanco;
  &:hover {
    background-color: transparent;
    color: lighten($color: $color-blanco, $amount: 10);
    border-color: lighten($color: $color-blanco, $amount: 10);
  }
}

.boton--outline {
  background-color: transparent;
  color: $color-texto;
  //border: 2px solid $color-texto;
  text-decoration: underline;
  &:hover {
    background-color: transparent;
    color: lighten($color: $color-texto, $amount: 10);
    //border-color: lighten($color: $color-texto, $amount: 10);
  }
}

.boton--footer {
  background-color: $color-alternativo;
  color: $color-texto;
  border-color: $color-alternativo;
  padding: 4px 20px;
  margin-top: 1rem;
  width: fit-content;
  text-align: center;
  &:hover {
    background-color: $color-secundario-dark;
    border-color: $color-secundario-dark;
    color: $color-background;
  }
}

.boton--up {
  text-transform: uppercase;
}

.boton--slim {
  padding: 4px 12px;
}

.boton--center {
  text-align: center;
}
