.single-medico {
  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10rem;
    font-size: 1.25rem;
    @media screen and (max-width: 991px) {
      gap: 3rem;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }

  &__left {
  }

  &__img {
    width: 100%;
    height: auto;
  }

  &__nombre {
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.4rem;
    margin-bottom: 1rem;
  }

  &__especialidad {
    &::after {
      content: ",\0000a0";
    }
  }

  &__numero {
  }

  &__right {
    ul {
      list-style: none;
      padding-left: 1rem;
    }
    li {
      vertical-align: middle;
      margin-bottom: 1rem;
      margin-left: 4px;
      &::before {
        //   content: "+";
        //   margin-right: 4px;
        //   color: $color-secundario;
        //   font-size: 2rem;
        content: "\002B";
        color: darken($color-secundario, $amount: 10);
        font-weight: bold;
        display: inline-block;
        width: 1.3rem;
        margin-left: -1.3rem;
        font-size: 1.5rem;
      }
    }
  }
}

.single-medico__especialidad:nth-last-child(2)::after {
  content: " y\0000a0";
}
.single-medico__especialidad:nth-last-child(1)::after {
  content: ".";
}
