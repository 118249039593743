.grid-woo {
  display: flex;
  // grid-template-columns: 2fr 10fr;
  // gap: 3rem;
  // @media (max-width: 767px) {
  //   grid-template-columns: 1fr;
  //   grid-gap: 1rem;
  // }
  &__sidebar {
    display: block;
    // grid-template-columns: repeat(4, 1fr);
    // gap: 1.5rem;
    @media (max-width: 767px) {
      display: none;
    }
  }
  &__sidebar-responsive {
    display: block;
    // grid-template-columns: repeat(4, 1fr);
    // gap: 1.5rem;
    @media (min-width: 768px) {
      display: none;
    }
  }

  &__content {
    .woocommerce-result-count {
      display: none;
    }
    select.orderby {
      margin-bottom: 2rem;
      @media screen and (max-width: 991px) {
        margin-bottom: 1rem;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }
}

.widget--woo {
  margin-bottom: 0rem;
  @media (max-width: 575px) {
    margin-bottom: 1rem;
  }
  h4.widget__title {
    display: none;
  }
  input {
    box-shadow: none !important;
  }
}

.single-product {
  .grid-woo {
    display: block;
  }
  .grid-woo__sidebar-responsive,
  .grid-woo__sidebar {
    display: none;
  }
}

.tax-product_cat .grid-woo,
.post-type-archive-product .grid-woo {
  margin-top: 2rem !important;
  @media screen and (max-width: 767px) {
    margin-top: 0.5rem !important;
  }
}

.page-woocommerce {
  padding-top: 2rem;
  padding-bottom: 4rem;
  &--product {
    padding-top: 0;
    // background-color: $color-background;
  }
}

.term-description {
  margin-top: 2.5rem;
}

.widget-tallas {
  margin-bottom: 2rem;
  &__widget {
    width: 100%;
    @media screen and (min-width: 992px) {
      width: 30%;
    }
  }
}

ul.products {
  padding-left: 0;
  list-style: none;
  display: grid;
  width: 100%;
  margin-top: 1rem;
  @media screen and (max-width: 575px) {
    // padding-left: 2rem;
    // padding-right: 2rem;
  }
  li.product {
    // padding: 1rem;
    background-color: $color-background;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .button {
      background: $color-gris-04;
      border-color: $color-gris-04;
      border-width: 2px !important;
      color: $color-background;
      border-style: solid;
      // @include input-buttons-sm;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      transition: $trans;
      &:hover {
        .bi {
          transition: $trans;
          margin-right: 15px;
        }
      }
      .bi {
        margin-right: 5px;
        margin-bottom: 5px;
        transition: $trans;
      }
    }
    .added_to_cart {
    }
  }

  .product__cat {
    margin-bottom: $font-size / 3;
    color: $color-gris-03;
    font-size: 0.75rem;
    letter-spacing: 0.5px;
    @media (max-width: 575px) {
      margin-bottom: 0.2rem !important;
    }
  }

  .woocommerce-loop-product__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    text-align: center;
    @media (max-width: 575px) {
      align-items: center;
    }
    .onsale {
      position: absolute;
      background-color: $color-alternativo;
      color: $color-blanco;
      padding: 3px 8px 4px;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: bold;
      top: 0.5rem;
      left: 0.5rem;
      @media screen and (max-width: 575px) {
        font-size: 0.7rem;
        padding: 2px 8px 3px;
      }
    }
  }

  .woocommerce-loop-product__link .wp-post-image {
    width: 100%;
    height: auto;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    margin-bottom: 1rem;
    border-radius: 4px;
  }

  .attachment-woocommerce_thumbnail {
    width: 100% !important;
    height: auto;
    margin-bottom: 1rem;
    // border-radius: $border-radius-min;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    @media (max-width: 575px) {
      margin-bottom: 0.8rem !important;
    }
  }

  .woocommerce-loop-product__title {
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 500;
    // width: 90%;
    display: block;
    color: $color-gris-03;
    font-weight: bold;
    @media (max-width: 575px) {
      font-size: 1rem;
      font-family: $typo-principal;
    }
  }

  .price {
    // @media (max-width: 575px) {
    //   display: flex;
    //   justify-content: flex-start;
    //   width: 100%;
    // }
    .woocommerce-Price-amount {
      color: $color-principal-dark;
      font-size: 1.125rem;
    }

    .woocommerce-Price-currencySymbol {
      //font-size: $font-size / 1.5;
    }

    ins {
      text-decoration: none !important;
      bdi {
        color: $color-alternativo;
        font-size: 1.125rem;
        margin-left: 0.5rem;
      }
    }
    del {
    }
  }
}

.columns-3 {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}

.columns-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem 2rem;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem 1rem;
  }
  @media (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem 1rem;
  }
}

.columns-5 {
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 3rem 2rem;
  @media screen and (max-width: 1199px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem 1rem;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem 2rem;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem 1rem;
  }
  @media (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem 1rem;
  }
}

.page-template-front-page .columns-4,
.term-zona-outlet .columns-4 {
  @media (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem 1rem;
  }
}

.columns-6 {
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2rem;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}

.products {
  li.product-category {
    img {
      width: 100%;
      height: auto;
      border: 0px solid rgba($color: #000000, $alpha: 0.1);
      margin-bottom: 1.5rem;
    }
    h2.woocommerce-loop-category__title {
      font-size: 1rem;
      color: $color-gris-02;
      text-align: center;
    }
  }
}

.term-description {
  margin-bottom: 1rem;
  font-size: 0.85rem;
}

.woocommerce-result-count {
  display: inline-block;
  color: $color-gris-02;
  margin-top: 10px;
  font-size: $font-size-small;

  @media (max-width: 575px) {
    font-size: $font-size-small;
    display: none;
  }
}

.woocommerce-ordering {
  display: inline-block;
  float: right;
  clear: both;
  @media (max-width: 991px) {
    float: none;
  }
}

/* Paginador -------------------- */

.woocommerce-pagination {
  text-align: center;

  ul.page-numbers {
    list-style: none;
    display: inline-flex;
    justify-content: center;
    width: auto !important;
    border: 0px solid $color-principal-dark;
    padding: 2px;
    border-radius: 3px;
  }

  li {
    display: inline-block;
  }

  li .page-numbers {
    padding: 10px 20px;
    background-color: $color-principal-dark;
    color: #fff;
    margin: 2px;
    display: block;
    border-radius: 3px;
    font-size: 1.2rem;
  }

  li .page-numbers.current {
    background-color: $color-principal-dark;
  }
  li .page-numbers:hover {
    background-color: $color-principal-dark;
  }
}
