@charset "UTF-8";
body {
  background-color: white;
  font-size: 1rem;
  color: #3c3c3b;
  font-weight: 400;
  font-family: "karmina-sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smooth: antialiased; }
  @media screen and (max-width: 575px) {
    body {
      font-size: 0.9rem; } }

a {
  color: #3c3c3b;
  text-decoration: none; }
  a:hover {
    color: #f88379;
    text-decoration: none; }

.menu-visible {
  overflow-y: hidden; }

.pad-b-min {
  padding-bottom: 3rem; }

.mar-b-min {
  margin-bottom: 3rem; }

.pad-b {
  padding-bottom: 7rem; }

.mar-b {
  margin-bottom: 7rem; }

.pad-b-max {
  padding-bottom: 12rem; }

.mar-b-max {
  margin-bottom: 12rem; }

.pad-t-min {
  padding-top: 3rem; }

.mar-t-min {
  margin-top: 3rem; }

.pad-t {
  padding-top: 7rem; }

.mar-t {
  margin-top: 7rem; }

.pad-t-max {
  padding-top: 12rem; }

.mar-t-max {
  margin-top: 12rem; }

.pad-min {
  padding: 3rem 0; }

.pad {
  padding: 7rem 0; }

.pad-max {
  padding: 12rem 0; }

.mar-min {
  margin: 3rem 0; }

.mar {
  margin: 7rem 0; }

.mar-max {
  margin: 12rem 0; }

@media screen and (max-width: 575px) {
  .pad-b-min {
    padding-bottom: 1.5rem; }
  .mar-b-min {
    margin-bottom: 1.5rem; }
  .pad-b {
    padding-bottom: 3rem; }
  .mar-b {
    margin-bottom: 3rem; }
  .pad-b-max {
    padding-bottom: 7rem; }
  .mar-b-max {
    margin-bottom: 7rem; }
  .pad-t-min {
    padding-top: 1.5rem; }
  .mar-t-min {
    margin-top: 1.5rem; }
  .pad-t {
    padding-top: 3rem; }
  .mar-t {
    margin-top: 3rem; }
  .pad-t-max {
    padding-top: 7rem; }
  .mar-t-max {
    margin-top: 7rem; }
  .pad-min {
    padding: 1.5rem 0; }
  .pad {
    padding: 3rem 0; }
  .pad-max {
    padding: 7rem 0; }
  .mar-min {
    margin: 1.5rem 0; }
  .mar {
    margin: 3rem 0; }
  .mar-max {
    margin: 7rem 0; } }

.color-primario {
  background-color: #f8d3c3; }

.bg-primario {
  background-color: #f8d3c3; }

.bg-gris-light {
  background-color: rgba(0, 0, 0, 0.15); }

.font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smooth: antialiased; }

@media screen and (max-width: 991px) {
  .desktop {
    display: none; } }

@media screen and (min-width: 992px) {
  .mvl {
    display: none; } }

.titulos {
  margin-bottom: 3rem; }
  @media screen and (max-width: 991px) {
    .titulos__desktop {
      display: none; } }
  @media screen and (min-width: 992px) {
    .titulos__mvl {
      display: none; } }
  @media screen and (max-width: 991px) {
    .titulos__mvl {
      margin-bottom: 0; } }
  @media screen and (max-width: 767px) {
    .titulos__hero-01 {
      font-size: 2.4rem !important;
      color: white; } }
  @media screen and (max-width: 575px) {
    .titulos__hero-01 {
      font-size: 1.9rem !important; } }
  @media screen and (max-width: 767px) {
    .titulos__hero-05 {
      font-size: 1.3rem !important;
      color: white; } }
  @media screen and (max-width: 575px) {
    .titulos__hero-05 {
      font-size: 1.2rem !important; } }
  .titulos__head, .titulos__doscolores {
    font-weight: bold;
    font-size: 2rem;
    margin-top: 0; }
    .titulos__head span, .titulos__doscolores span {
      color: white; }
    @media screen and (max-width: 991px) {
      .titulos__head, .titulos__doscolores {
        font-size: 1.8rem; } }
    @media screen and (max-width: 575px) {
      .titulos__head, .titulos__doscolores {
        font-size: 1.6rem; } }
  .titulos__header-01 {
    font-weight: bold;
    font-size: 4.0625rem;
    margin-top: 0; }
    .titulos__header-01 span {
      color: white; }
    @media screen and (max-width: 991px) {
      .titulos__header-01 {
        font-size: 3.6rem; } }
    @media screen and (max-width: 575px) {
      .titulos__header-01 {
        font-size: 2.8rem; } }
  .titulos__header-02 {
    font-size: 3.0625rem;
    line-height: 1.2;
    font-weight: 600; }
    @media screen and (max-width: 991px) {
      .titulos__header-02 {
        font-size: 2.8rem; } }
    @media screen and (max-width: 575px) {
      .titulos__header-02 {
        font-size: 2.2rem; } }
  .titulos__header-03 {
    font-weight: bold;
    font-size: 2.185rem;
    margin-bottom: 0; }
    .titulos__header-03 p {
      margin-bottom: 0.5rem; }
  .titulos__header-04 {
    font-weight: bold;
    font-size: 1.3rem;
    margin-top: 0; }
  .titulos__header-05 {
    font-size: 1.375rem;
    line-height: 1.2;
    font-family: "karmina-sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-smooth: antialiased; }
  .titulos__header-06 {
    text-transform: uppercase;
    font-size: 0.875rem;
    letter-spacing: 1.4px;
    font-weight: 600; }
  .titulos__blog {
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 0; }
    .titulos__blog p {
      margin-bottom: 0.5rem; }
  .titulos__post {
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 0;
    font-family: "karmina", serif; }
    .titulos__post p {
      margin-bottom: 0.5rem; }
    @media screen and (max-width: 575px) {
      .titulos__post {
        font-size: 2rem; } }
  .titulos--principal {
    color: #f88379; }
  .titulos--secundario {
    color: #4a4a24; }
  .titulos--alternativo {
    color: #dee07d; }
  .titulos--blanco {
    color: white; }
  .titulos__min {
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 0rem;
    margin-bottom: 3px; }
    @media screen and (max-width: 575px) {
      .titulos__min {
        font-size: 1.3rem; } }
  .titulos__subtitulo {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 7rem; }
    @media screen and (max-width: 575px) {
      .titulos__subtitulo {
        margin-top: 1.5rem;
        font-size: 90%;
        margin-bottom: 3rem; } }
  .titulos__subtitulo--padb {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    letter-spacing: 1px; }
  @media screen and (max-width: 575px) {
    .titulos {
      margin-bottom: 1.5rem; } }
  .titulos__line::before {
    content: "";
    display: block;
    background-color: #f8d3c3;
    margin: 0 0 0.5rem 0;
    width: 3.6rem;
    height: 0.7rem;
    border-radius: 50px; }
  .titulos--sub {
    margin-bottom: 0; }
  .titulos--sub-02 {
    margin-bottom: 1rem; }
  .titulos--sub-03 {
    margin-bottom: 2rem; }
  .titulos--up {
    text-transform: uppercase; }
  .titulos--light {
    color: white; }
    .titulos--light.titulos__line::before {
      background-color: white; }
  .titulos--green {
    color: #f8d3c3; }

a.titulos__header--01,
a.titulos__header--02,
a.titulos__header--03,
a.titulos__header--04 {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none; }

a .titulos__header--01,
a .titulos__header--02,
a .titulos__header--03,
a .titulos__header--04 {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none; }

.titulos--padb {
  margin-bottom: 1rem; }

.titulos--center {
  text-align: center; }
  @media screen and (max-width: 575px) {
    .titulos--center {
      text-align: end; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "karmina", serif; }

.negrita {
  font-weight: 700; }

.header {
  z-index: 1000;
  position: fixed;
  width: 100%;
  color: white;
  transition: background-color 0.5s linear, top 0.5s ease-in-out;
  top: 0;
  z-index: 30; }
  .header__container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .header__content {
    width: 100%; }
  .header__logo {
    display: inline-block;
    height: 32px;
    width: auto;
    transition: 0.3s all ease-in-out; }
    @media screen and (max-width: 575px) {
      .header__logo {
        width: auto;
        height: 28px;
        transition: 0.2s all ease-in-out; } }
  .header__navbar {
    padding: 3rem 0;
    display: flex;
    justify-content: flex-start;
    transition: 0.3s all ease-in-out; }
    @media screen and (max-width: 575px) {
      .header__navbar {
        padding: 1rem 0; } }
  .header__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 2rem; }
    @media screen and (max-width: 991px) {
      .header__right {
        margin-left: 0;
        margin-right: 2rem; } }
    @media screen and (max-width: 767px) {
      .header__right {
        margin-right: 1.5rem; } }
    .header__right .bi {
      font-size: 1.8rem; }
      @media screen and (min-width: 1199px) {
        .header__right .bi {
          font-size: 1.5rem; } }
  .header__burger {
    display: flex;
    align-items: center;
    margin-right: 1rem; }
    .header__burger a {
      color: #3c3c3b; }
      .header__burger a i {
        font-size: 2.5rem;
        transition: 0.2s all ease-in-out; }
      .header__burger a:hover {
        color: #1a191e; }
    @media screen and (min-width: 992px) {
      .header__burger {
        display: none; } }
    @media screen and (max-width: 991px) {
      .header__burger {
        display: inline-block; } }
    @media screen and (max-width: 575px) {
      .header__burger {
        font-size: 1.5rem; } }

.header--sticky {
  background-color: transparent;
  box-shadow: none;
  padding: 1rem 0;
  transition: 0.3s all ease-in-out;
  z-index: 30; }

.header--scroll {
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s all ease-in-out;
  z-index: 30; }
  .header--scroll .header__navbar {
    padding: 1rem 0;
    transition: 0.3s all ease-in-out; }
    @media screen and (max-width: 575px) {
      .header--scroll .header__navbar {
        padding: 0.5rem 0;
        transition: 0.3s all ease-in-out; } }
  .header--scroll .header__right a {
    color: white;
    transition: 0.2s all ease-in-out; }
  @media screen and (max-width: 575px) {
    .header--scroll .header__burger i {
      transition: 0.3s all ease-in-out;
      font-size: 2rem; } }

.header--scroll .header__logo {
  transition: 0.3s all ease-in-out;
  filter: brightness(100);
  height: 24px; }
  @media screen and (max-width: 575px) {
    .header--scroll .header__logo {
      height: 22px; } }

.page-template-front-page .header__logo {
  filter: brightness(100); }

.menu-overlay {
  width: 100%;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  z-index: 40;
  list-style: none; }
  .menu-overlay--oculto {
    display: none;
    transition: 0.2s all ease-in-out; }
  .menu-overlay--activo {
    display: flex;
    flex-direction: column;
    transition: 0.2s all ease-in-out;
    z-index: 1000; }
  .menu-overlay__navbar {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem; }
    @media screen and (max-width: 575px) {
      .menu-overlay__navbar {
        margin-top: 0;
        padding-top: 0; } }
  .menu-overlay__cerrar {
    z-index: 2;
    position: relative;
    font-size: 3rem;
    line-height: 0;
    padding-right: 3rem;
    padding-top: 3.1rem;
    color: #f88379;
    text-align: right; }
    @media screen and (max-width: 575px) {
      .menu-overlay__cerrar {
        padding-right: 1.5rem;
        padding-top: 1.2rem; } }
    .menu-overlay__cerrar a {
      color: #f88379; }
      .menu-overlay__cerrar a:hover {
        color: rgba(248, 131, 121, 0.7); }
    @media screen and (max-width: 575px) {
      .menu-overlay__cerrar {
        font-size: 3rem; } }
  .menu-overlay__menu {
    width: 100%;
    padding: 0 1.5rem; }
  .menu-overlay__nav {
    text-align: flex-start;
    padding-left: 0;
    list-style: none;
    font-size: 1rem; }
    .menu-overlay__nav li.menu-item {
      margin-bottom: 0.7rem; }
      .menu-overlay__nav li.menu-item > a {
        font-size: 1.2rem;
        font-weight: bold; }
      .menu-overlay__nav li.menu-item ul.sub-menu {
        list-style: none;
        display: flex;
        justify-content: flex-start;
        padding-left: 0;
        flex-wrap: wrap; }
        .menu-overlay__nav li.menu-item ul.sub-menu li {
          margin: 0 5px 5px 0px; }
          .menu-overlay__nav li.menu-item ul.sub-menu li a {
            background-color: #dee07d;
            color: #242328 !important;
            font-size: 1rem;
            padding: 4px 8px;
            border-radius: 4px;
            display: inline-block;
            font-weight: 700; }
            .menu-overlay__nav li.menu-item ul.sub-menu li a:hover {
              color: #f88379 !important; }
    .menu-overlay__nav .menu-item-1848 {
      display: none; }
  .menu-overlay__botones {
    width: 100%;
    padding: 0 1.5rem; }
  .menu-overlay__boton {
    display: block;
    width: 100%;
    padding: 4px 8px !important;
    text-align: center; }

.menu-overlay__boton:nth-last-child(1) {
  margin-top: 1rem; }

.footer {
  background-color: #242328;
  color: white;
  padding-top: 6rem; }
  @media screen and (max-width: 575px) {
    .footer {
      padding-top: 3rem; } }
  .footer__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem; }
    @media screen and (max-width: 991px) {
      .footer__container {
        grid-template-columns: repeat(2, 1fr); } }
    @media screen and (max-width: 575px) {
      .footer__container {
        gap: 2rem;
        margin-bottom: 2rem; } }
  .footer .f-contacto__datos {
    display: flex;
    flex-direction: column; }
  .footer .f-contacto__item {
    color: white;
    text-decoration: none;
    margin-bottom: 5px; }
    .footer .f-contacto__item i {
      color: white;
      margin-right: 1rem; }
  @media screen and (max-width: 575px) {
    .footer__item--nosotros {
      grid-column: 1 / span 2; } }
  .footer__item--rs .socicon {
    background-color: transparent !important;
    color: #dee07d !important;
    font-size: 1.5rem !important; }
    .footer__item--rs .socicon:hover {
      color: #e8eaa6 !important; }
  .footer__item--rs .socicon-facebook {
    padding-left: 0 !important; }
  .footer__logo {
    max-width: 220px;
    margin-bottom: 3rem; }
    @media screen and (max-width: 575px) {
      .footer__logo {
        max-width: 160px; } }
  .footer__euro {
    width: 60%; }
    .footer__euro .wp-block-media-text {
      display: grid;
      grid-template-columns: 1fr 6fr;
      gap: 1rem;
      color: rgba(255, 255, 255, 0.5); }
      @media screen and (max-width: 767px) {
        .footer__euro .wp-block-media-text {
          grid-template-columns: 1fr 4fr; } }
      .footer__euro .wp-block-media-text__media {
        align-self: flex-start !important; }
    @media screen and (max-width: 575px) {
      .footer__euro .wp-block-media-text.is-stacked-on-mobile {
        grid-template-columns: 1fr 2fr !important; } }
    .footer__euro img {
      width: 100%;
      height: auto; }
    .footer__euro .wp-block-media-text__content {
      align-self: center;
      padding: 0 !important;
      font-size: 0.875rem !important; }
      @media screen and (max-width: 575px) {
        .footer__euro .wp-block-media-text__content {
          font-size: 12px !important; } }
      .footer__euro .wp-block-media-text__content p {
        margin-bottom: 0;
        -webkit-font-smoothing: antialiased;
        -moz-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -ms-font-smoothing: antialiased;
        -o-font-smoothing: antialiased;
        font-smooth: antialiased; }
    @media screen and (max-width: 991px) {
      .footer__euro {
        width: 100%; } }
  .footer .widget__title {
    font-family: "karmina-sans", sans-serif;
    color: #dee07d;
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1.5rem; }
    @media screen and (max-width: 575px) {
      .footer .widget__title {
        margin-bottom: 0.75rem; } }

.copyright {
  background-color: #242328;
  color: white; }
  .copyright__content {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 991px) {
      .copyright__content {
        flex-direction: column;
        text-align: center; } }
  .copyright__item {
    color: white; }
  .copyright a {
    color: #dee07d;
    text-decoration: none; }
    .copyright a:hover {
      color: #e8eaa6; }
  .copyright__social .social-icon {
    color: #595959 !important; }
    .copyright__social .social-icon:hover {
      color: #f8d3c3 !important; }
  .copyright__social .zoom-social_icons-list__item {
    margin-bottom: 0 !important; }

.compres {
  padding: 2rem 0; }
  .compres__content {
    display: flex;
    justify-content: space-around; }
  .compres__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center; }
    @media screen and (max-width: 575px) {
      .compres__item {
        margin-bottom: 1rem; } }
    .compres__item:last-child {
      margin-bottom: 0; }
  @media screen and (max-width: 575px) {
    .compres__icono {
      flex: 0 0 10%;
      display: flex;
      align-items: center;
      justify-content: center; } }
  .compres__icono .bi {
    font-size: 2rem;
    color: #f88379; }
  .compres__titulo {
    font-weight: 500;
    font-size: 0.8rem;
    color: #fcc6c2; }
    .compres__titulo span {
      display: block;
      color: #f88379; }

.navbar .ubermenu .ubermenu-item.ubermenu-active > .ubermenu-submenu-drop,
.navbar .ubermenu-nojs .ubermenu-item:hover > .ubermenu-submenu-drop {
  display: flex; }

.navbar .ubermenu
.ubermenu-item:nth-last-child(1)
.ubermenu-submenu-drop.ubermenu-submenu-align-full_width {
  right: 0; }

.navbar .ubermenu .ubermenu-submenu-drop.ubermenu-submenu-align-full_width {
  width: auto !important;
  background-color: white;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  left: auto;
  display: flex;
  flex-direction: column; }
  .navbar .ubermenu .ubermenu-submenu-drop.ubermenu-submenu-align-full_width .ubermenu-target {
    color: #3c3c3b; }
    .navbar .ubermenu .ubermenu-submenu-drop.ubermenu-submenu-align-full_width .ubermenu-target:hover {
      background-color: #3c3c3b;
      color: white; }

.ubermenu {
  margin-left: auto !important; }
  @media screen and (max-width: 991px) {
    .ubermenu-nav {
      display: none !important; } }
  .ubermenu-target {
    color: #3c3c3b;
    font-size: 1.1rem;
    font-weight: 700 !important;
    padding: 11px 16px !important; }
    .ubermenu-target:hover {
      color: #3c3c3b; }

.page-template-front-page .ubermenu .ubermenu-target,
.page-template-front-page button.ubermenu-responsive-toggle-align-full,
.page-template-front-page .header__burger a,
.page-template-front-page .header__right a {
  color: white; }

.ubermenu .ubermenu-item:nth-last-child(1) .ubermenu-target {
  margin-right: 0;
  background-color: white;
  color: #3c3c3b;
  margin-left: 1.4rem;
  border-radius: 2px; }

.ubermenu .ubermenu-submenu .ubermenu-item .ubermenu-target {
  margin-right: 0;
  margin-left: 0;
  background-color: transparent; }

.header--scroll .ubermenu .ubermenu-target,
.header--scroll button.ubermenu-responsive-toggle-align-full,
.header--scroll .header__burger a {
  color: white;
  transition: 0.3s all ease-in-out; }

.header .nav-link {
  font-size: 0.85rem; }

#navbarSupportedContent {
  justify-content: flex-end; }

.categorias-home {
  background-color: white;
  padding: 0.3rem 1.5rem 0rem; }
  @media screen and (min-width: 768px) {
    .categorias-home--home {
      display: none; } }
  .categorias-home--shop ul.product-categories {
    padding-top: 0 !important; }

.single-product .categorias-home {
  display: none; }

.categorias-header__title {
  display: none; }

.categorias-header ul.product-categories {
  margin-left: -1.1rem;
  margin-right: -1.1rem;
  padding-left: 1rem;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1rem;
  padding-top: 1rem; }
  @media screen and (min-width: 768px) {
    .categorias-header ul.product-categories {
      flex-wrap: wrap;
      margin-left: 0rem;
      margin-right: 0rem; } }
  @media screen and (max-width: 767px) {
    .categorias-header ul.product-categories {
      justify-content: flex-start; } }
  .categorias-header ul.product-categories li.current-cat a {
    background-color: #1a191e !important; }
  .categorias-header ul.product-categories li.cat-item {
    margin: 0 5px; }
    @media screen and (min-width: 768px) {
      .categorias-header ul.product-categories li.cat-item {
        margin: 0 5px 5px 0; } }
    @media screen and (max-width: 767px) {
      .categorias-header ul.product-categories li.cat-item {
        margin: 0 0 0 8px; } }
    @media screen and (max-width: 767px) {
      .categorias-header ul.product-categories li.cat-item:first-child {
        margin-left: 0 !important; } }
    .categorias-header ul.product-categories li.cat-item a {
      padding: 9px 1rem 9px;
      color: white;
      background-color: #888888;
      border-radius: 50px;
      font-size: 0.8rem;
      transition: 0.2s all ease-in-out;
      display: inline-flex;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-family: inherit;
      white-space: nowrap; }
      .categorias-header ul.product-categories li.cat-item a:hover {
        transition: 0.2s all ease-in-out;
        background-color: #f8d3c3;
        color: white !important; }
      .categorias-header ul.product-categories li.cat-item a:focus {
        transition: 0.2s all ease-in-out;
        background-color: #888888;
        color: white !important; }

@media screen and (min-width: 768px) {
  .categorias-home--shop ul.product-categories {
    justify-content: flex-start; } }

@media screen and (max-width: 767px) {
  .categorias-home--shop {
    margin-bottom: 1rem; } }

@media screen and (max-width: 767px) {
  .categorias-home--shop .container {
    max-width: 100%;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
    padding-right: 0; } }

.single-product .c-list {
  display: none; }

.c-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 1rem;
  padding-top: 1rem; }
  @media screen and (min-width: 768px) {
    .c-list {
      flex-wrap: wrap;
      padding-left: 0;
      padding-right: 0; } }
  @media screen and (min-width: 768px) {
    .c-list--home {
      display: none; } }
  @media screen and (max-width: 767px) {
    .c-list--shop {
      padding-left: 0; } }
  .c-list__item {
    display: inline-flex;
    margin-right: 5px; }
    @media screen and (min-width: 768px) {
      .c-list__item {
        margin-bottom: 5px; } }
  .c-list__link {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border: 1px solid #f88379;
    color: #595959;
    padding: 7px 10px;
    border-radius: 3px;
    font-weight: medium;
    overflow: hidden;
    padding-right: 0.7rem;
    width: auto;
    padding-left: 0.7rem;
    overflow: hidden;
    transition: 0.2s all ease-in-out; }
    .c-list__link:hover {
      background-color: #f88379;
      color: white;
      transition: 0.2s all ease-in-out; }
    @media screen and (max-width: 767px) {
      .c-list__link {
        padding-top: 5px;
        padding-bottom: 5px; } }
    @media screen and (max-width: 360px) {
      .c-list__link {
        padding-top: 0px;
        padding-bottom: 0px; } }
    .c-list__link--active {
      background-color: #f88379;
      color: white;
      border-color: #f88379;
      pointer-events: none;
      font-weight: bold; }
      .c-list__link--active:hover {
        background-color: #f88379;
        cursor: default; }
  .c-list__img {
    width: 65px;
    height: auto;
    border-radius: 20px;
    transform: scale(0.8); }
    @media screen and (min-width: 768px) {
      .c-list__img {
        width: 60px; } }
  .c-list__name {
    white-space: nowrap; }
    .c-list__name--pic {
      padding-left: 0; }

.boton {
  display: inline-block;
  padding: 12px 30px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: #fff;
  transition: 0.2s all ease-in-out;
  text-decoration: none;
  border-radius: 2px;
  border: 2px solid;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smooth: antialiased; }
  @media screen and (max-width: 991px) {
    .boton {
      padding: 10px 22px; } }
  .boton:hover {
    text-decoration: none;
    transition: 0.2s all ease-in-out; }
  .boton:disabled {
    background-color: #fbb0a9; }
    .boton:disabled:hover {
      background-color: #fbb0a9; }

@media screen and (max-width: 991px) {
  .boton.me-2 {
    margin-right: 0.2rem !important; }
  .boton.ms-2 {
    margin-left: 0.2rem !important; } }

.boton--principal {
  background-color: #f88379;
  color: white;
  border-color: #f88379; }
  .boton--principal:hover {
    background-color: #fbb0a9;
    border-color: #fbb0a9;
    color: white; }

.boton--secundario {
  background-color: #4a4a24;
  color: white;
  border-color: #4a4a24; }
  .boton--secundario:hover {
    background-color: #6c6c35;
    border-color: #6c6c35;
    color: white; }

.boton--blanco {
  background-color: white;
  color: #3c3c3b;
  border-color: white; }
  .boton--blanco:hover {
    background-color: #e6e6e6;
    color: #3c3c3b;
    border-color: #e6e6e6; }

.boton--transparent {
  background-color: transparent;
  color: #3c3c3b;
  border-color: #3c3c3b; }
  .boton--transparent:hover {
    background-color: transparent;
    color: #565654;
    border-color: #565654; }

.boton--transparent-hero {
  background-color: transparent;
  color: white;
  border-color: white; }
  .boton--transparent-hero:hover {
    background-color: transparent;
    color: white;
    border-color: white; }

.boton--outline {
  background-color: transparent;
  color: #3c3c3b;
  text-decoration: underline; }
  .boton--outline:hover {
    background-color: transparent;
    color: #565654; }

.boton--footer {
  background-color: #dee07d;
  color: #3c3c3b;
  border-color: #dee07d;
  padding: 4px 20px;
  margin-top: 1rem;
  width: fit-content;
  text-align: center; }
  .boton--footer:hover {
    background-color: #4a4a24;
    border-color: #4a4a24;
    color: white; }

.boton--up {
  text-transform: uppercase; }

.boton--slim {
  padding: 4px 12px; }

.boton--center {
  text-align: center; }

.hero {
  position: relative;
  height: 640px;
  background: url("../../assets/site/bg-hero-03.jpg") no-repeat center center;
  background-size: cover;
  z-index: 1; }
  @media screen and (max-width: 767px) {
    .hero {
      height: 430px; } }
  .hero__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, #00deff, #a7d714);
    z-index: 2;
    mix-blend-mode: multiply; }
  .hero__overlay-2 {
    z-index: 3;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: url("../../assets/site/bg-groovepaper.png") center center;
    mix-blend-mode: multiply; }
  .hero__container {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 4; }
    @media screen and (max-width: 575px) {
      .hero__container {
        align-items: flex-end;
        padding-bottom: 1.5rem; } }
  .hero__content {
    padding-top: 3rem;
    position: relative;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
    @media screen and (max-width: 991px) {
      .hero__content {
        width: 60%; } }
    @media screen and (max-width: 767px) {
      .hero__content {
        width: 100%; } }
  .hero__header {
    width: 100%; }
  .hero__logo {
    width: 70px;
    height: auto;
    margin-bottom: 2rem; }
    @media screen and (max-width: 767px) {
      .hero__logo {
        width: 40px;
        margin-bottom: 1rem; } }
  .hero__title {
    font-size: 4.0625rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
    @media screen and (max-width: 991px) {
      .hero__title {
        font-size: 3rem; } }
    @media screen and (max-width: 575px) {
      .hero__title {
        font-size: 2rem; } }
  .hero__subtitle {
    font-family: "karmina-sans", sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
    margin-bottom: 2rem;
    color: #fff;
    font-weight: 500;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
    @media screen and (max-width: 575px) {
      .hero__subtitle {
        font-size: 1rem;
        margin-bottom: 0.8rem; } }
  @media screen and (max-width: 991px) {
    .hero__footer {
      display: block; }
      .hero__footer .boton {
        margin: 0.5rem 0.5rem 0.5rem 0 !important; } }

.page-title {
  position: relative;
  padding-top: 10rem;
  padding-bottom: 3.75rem;
  background-color: #dee07d; }
  @media screen and (max-width: 575px) {
    .page-title {
      padding-top: 7rem;
      padding-bottom: 2rem; } }
  .page-title__content {
    position: relative;
    color: #3c3c3b; }
  .page-title__breadcrumbs {
    color: #3c3c3b;
    font-size: 0.8rem;
    text-transform: uppercase; }
    .page-title__breadcrumbs a {
      color: #3c3c3b;
      font-weight: bold;
      text-decoration: none; }
  .page-title__header-01 {
    font-weight: bold;
    font-size: 4.0625rem;
    margin-top: 0; }
    .page-title__header-01 span {
      color: white; }
    @media screen and (max-width: 991px) {
      .page-title__header-01 {
        font-size: 3.6rem; } }
    @media screen and (max-width: 575px) {
      .page-title__header-01 {
        font-size: 2.4rem; } }
  .page-title__header-03 {
    font-weight: bold;
    font-size: 2.185rem;
    margin-bottom: 0; }
    .page-title__header-03 p {
      margin-bottom: 0.5rem; }
    @media screen and (max-width: 575px) {
      .page-title__header-03 {
        font-size: 1.8rem; } }

.page-template-page-estetica-cirugia .page-title,
.page-template-page-centro-estetico .page-title,
.page-id-918 .page-title,
.single-servicio .page-title,
.page-template-page-citas .page-title,
.page-template-page-cuadro-estetico .page-title,
.woocommerce-page .page-title {
  background-color: #f8d3c3; }

.page-id-1033 .page-title,
.page-id-13 .page-title {
  background-color: #dee07d; }

.wpcf7 .form-group {
  margin-bottom: 1rem; }

.wpcf7 .form-control {
  width: 100%;
  padding: 0.675rem 0.75rem;
  font-size: 0.9rem;
  border-radius: 0;
  background-color: white;
  color: #3c3c3b; }

.wpcf7 .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  /* Firefox */ }

.wpcf7 .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.5); }

.wpcf7 .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.5); }

.wpcf7 .wpcf7-submit {
  display: inline-block;
  border: 1px solid;
  border-radius: 0px;
  padding: 0.6rem 2.1875rem;
  text-decoration: none;
  transition: 0.2s all ease-in-out;
  background-color: #4a4a24;
  color: white;
  border: 1px solid #4a4a24; }
  .wpcf7 .wpcf7-submit:hover {
    background-color: #6c6c35;
    color: white; }

.wpcf7 .wpcf7-not-valid-tip {
  font-size: 12px;
  color: red; }

.wpcf7-form__margen {
  margin-bottom: 1rem; }

.wpcf7-list-item {
  margin-left: 0 !important; }

.wpcf7-acceptance {
  font-size: 0.8rem;
  color: #888888; }
  .wpcf7-acceptance a {
    color: #888888; }
    .wpcf7-acceptance a:hover {
      color: #f88379; }

.wpcf7-response-output {
  margin: 0 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px; }

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: white; }

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: white; }

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500;
  color: white; }

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important;
  color: white; }

body:not(.page-template-page-contacto) .grecaptcha-badge {
  visibility: hidden; }

.carrito-side {
  padding: 1rem;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  width: 20rem;
  height: 100%;
  opacity: 0;
  background-color: white;
  transition: 0.2s all ease-in-out; }
  .carrito-side.activo {
    opacity: 1;
    transition: 0.2s all ease-in-out; }
  .carrito-side__content {
    display: flex;
    flex-direction: column;
    margin-right: 1rem; }
  .carrito-side__cerrar {
    text-align: right; }
    .carrito-side__cerrar a {
      color: rgba(0, 0, 0, 0.6); }
      .carrito-side__cerrar a:hover {
        color: #f8d3c3; }

.cta-acf__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }

.cta-acf__pretitulo {
  font-size: 1.375rem;
  line-height: 1.2;
  font-family: "karmina-sans", sans-serif; }
  @media screen and (max-width: 575px) {
    .cta-acf__pretitulo {
      font-size: 1rem; } }

.cta-acf__titulo {
  font-size: 3.0625rem;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 2rem; }
  @media screen and (max-width: 991px) {
    .cta-acf__titulo {
      font-size: 2.8rem; } }
  @media screen and (max-width: 575px) {
    .cta-acf__titulo {
      font-size: 2.2rem; } }

@media screen and (max-width: 575px) {
  .cta-acf__boton.me-2 {
    margin: 0 0 0.5rem 0 !important; } }

@media screen and (max-width: 575px) {
  .cta-acf__boton.ms-2 {
    margin: 0 0 0 0 !important; } }

.cli-bar-container {
  justify-content: center !important;
  text-align: center; }

.cli-bar-message {
  width: auto !important; }

.cli-accept-button {
  background-color: #f8d3c3 !important;
  color: white !important;
  font-weight: bold !important;
  border-radius: 2px; }
  .cli-accept-button:hover {
    background-color: #f88379 !important;
    color: white !important; }

.page__entry p,
.page__entry ul,
.page__entry ol {
  margin-bottom: 1.8rem;
  font-size: 1.1rem;
  line-height: 1.65; }
  @media screen and (max-width: 575px) {
    .page__entry p,
    .page__entry ul,
    .page__entry ol {
      margin-bottom: 1.2rem;
      font-size: 1rem;
      line-height: 1.65; } }

.page__entry h2,
.page__entry h3,
.page__entry h4,
.page__entry h5,
.page__entry h6 {
  font-family: "karmina-sans", sans-serif;
  font-weight: bold;
  margin-bottom: 1.8rem; }
  @media screen and (max-width: 575px) {
    .page__entry h2,
    .page__entry h3,
    .page__entry h4,
    .page__entry h5,
    .page__entry h6 {
      margin-bottom: 1rem; } }

.page__entry .wp-block-image {
  margin-top: 1.8rem !important;
  margin-bottom: 1.8rem !important; }

.page__entry h2 {
  color: #4a4a24; }

.page__entry h3 {
  font-size: 1.4rem;
  color: #4a4a24; }

.page-id-1033 .page__entry h2 {
  color: #4a4a24; }

.page-id-1033 .page__entry h3 {
  color: #4a4a24; }

.claim__container {
  padding: 4rem 0;
  display: flex;
  justify-content: center; }

.claim__content {
  text-align: center; }

.claim__subtitle {
  font-size: 1rem; }
  @media screen and (max-width: 767px) {
    .claim__subtitle {
      line-height: 1.4; } }
  .claim__subtitle mark {
    background-color: #dee07d;
    padding-left: 3px;
    padding-right: 2px;
    margin-left: 2px;
    margin-right: 3px;
    border-radius: 2px; }
    @media screen and (max-width: 767px) {
      .claim__subtitle mark {
        padding-top: 1px;
        padding-bottom: 1px; } }

.estetica {
  margin-top: 2px;
  background-color: #f8d3c3; }
  .estetica__pretitulo {
    color: #f88379; }
  .estetica__descripcion {
    margin-bottom: 1rem !important;
    font-size: 2.1875rem;
    color: white;
    line-height: 1.3;
    margin-bottom: 2.75rem;
    font-weight: 600; }
    @media screen and (max-width: 991px) {
      .estetica__descripcion {
        font-size: 2rem; } }
    @media screen and (max-width: 575px) {
      .estetica__descripcion {
        font-size: 1.3rem;
        margin: 1rem 0 2rem 0; } }

.medico {
  margin-top: 2px;
  background-color: #e9eab6; }
  .medico__pretitulo {
    color: #4a4a24; }
  .medico__descripcion {
    margin-bottom: 1rem !important;
    font-size: 2.1875rem;
    color: white;
    line-height: 1.3;
    margin-bottom: 2.75rem;
    font-weight: 600; }
    @media screen and (max-width: 991px) {
      .medico__descripcion {
        font-size: 2rem; } }
    @media screen and (max-width: 575px) {
      .medico__descripcion {
        font-size: 1.3rem;
        margin: 1rem 0 2rem 0; } }

.tratamientos {
  background-color: #f8d3c3; }
  .tratamientos .owl-stage {
    padding: 2rem 0; }
    @media screen and (max-width: 575px) {
      .tratamientos .owl-stage {
        padding: 1rem 0; } }
    .tratamientos .owl-stage .owl-item {
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
      border-radius: 16px; }
  .tratamientos__subtitulo {
    max-width: 45%; }
  .tratamientos__item {
    position: relative;
    height: 300px;
    width: auto;
    border-radius: 16px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media screen and (max-width: 991px) {
      .tratamientos__item {
        height: 200px; } }
    @media screen and (max-width: 575px) {
      .tratamientos__item {
        height: 164px; } }
  .tratamientos__enlace {
    position: revert;
    color: white; }
  .tratamientos__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.3s all ease-in-out; }
  .tratamientos__info {
    position: absolute; }
  .tratamientos__nombre {
    color: white;
    font-size: 1.3125rem;
    font-weight: bold;
    letter-spacing: 2.1px;
    text-transform: uppercase; }
    .tratamientos__nombre:hover {
      color: white; }
    @media screen and (max-width: 991px) {
      .tratamientos__nombre {
        text-align: center;
        letter-spacing: 0; } }
  .tratamientos__img {
    border-radius: 16px;
    position: relative;
    width: 100%;
    height: auto; }

.tratamientos__item:hover .tratamientos__overlay {
  background-color: rgba(0, 0, 0, 0.6);
  transition: 0.3s all ease-in-out; }

.seguros {
  background-color: #e9eab6; }
  .seguros .owl-stage {
    padding: 2rem 0; }
    @media screen and (max-width: 575px) {
      .seguros .owl-stage {
        padding: 1rem 0; } }
    .seguros .owl-stage .owl-item {
      width: 180px;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
      background-color: white;
      border-radius: 16px; }
  .seguros__subtitulo {
    max-width: 45%; }
  .seguros__item {
    width: 100%;
    overflow: hidden; }
    .seguros__item img {
      width: 73% !important;
      max-width: 73% !important;
      max-height: 50% !important;
      margin: 0 auto; }

.instalaciones {
  background-color: #e9eab6; }
  .instalaciones .owl-stage {
    padding: 2rem 0; }
    @media screen and (max-width: 575px) {
      .instalaciones .owl-stage {
        padding: 1rem 0; } }
    .instalaciones .owl-stage .owl-item {
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
      border-radius: 16px; }
  .instalaciones__subtitulo {
    max-width: 45%; }
  .instalaciones__item {
    position: relative;
    height: 300px;
    width: 100%;
    border-radius: 16px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden; }
    @media screen and (max-width: 991px) {
      .instalaciones__item {
        height: 200px; } }
    @media screen and (max-width: 575px) {
      .instalaciones__item {
        height: 164px; } }
  .instalaciones__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.3s all ease-in-out; }
  .instalaciones__info {
    position: absolute;
    color: white; }
  .instalaciones__nombre {
    color: white;
    font-size: 1.3125rem;
    font-weight: bold;
    letter-spacing: 2.1px;
    text-transform: uppercase; }
    .instalaciones__nombre:hover {
      color: white; }
  .instalaciones__img {
    border-radius: 16px;
    position: relative;
    width: 100%;
    height: auto; }

.instalaciones__item:hover .instalaciones__overlay {
  background-color: rgba(0, 0, 0, 0.6);
  transition: 0.3s all ease-in-out; }

.bloque__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6rem; }
  @media screen and (max-width: 991px) {
    .bloque__content {
      grid-template-columns: 6fr 5fr;
      gap: 3rem; } }
  @media screen and (max-width: 767px) {
    .bloque__content {
      grid-template-columns: 1fr; } }

.bloque__titulo {
  font-size: 3.0625rem;
  line-height: 1.2;
  font-family: "karmina", serif;
  font-weight: 600; }
  @media screen and (max-width: 991px) {
    .bloque__titulo {
      font-size: 2.8rem; } }

.bloque__subtitulo {
  font-size: 1.375rem;
  line-height: 1.2;
  max-width: 45%;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smooth: antialiased; }
  @media screen and (max-width: 991px) {
    .bloque__subtitulo {
      max-width: 100%; } }

.bloque__descripcion {
  font-size: 2.1875rem;
  color: white;
  line-height: 1.3;
  margin: 2rem 0 1rem 0;
  font-weight: 600; }
  @media screen and (max-width: 991px) {
    .bloque__descripcion {
      font-size: 2rem; } }
  @media screen and (max-width: 575px) {
    .bloque__descripcion {
      font-size: 1.875rem;
      margin: 1rem 0 2rem 0; } }

.bloque__logo {
  width: 170px;
  height: auto;
  margin-bottom: 3rem; }

.bloque__botones .boton {
  font-weight: 600; }

@media screen and (max-width: 575px) {
  .bloque__botones .boton.ms-2 {
    margin-left: 0 !important;
    margin-top: 1rem; } }

.bloque__imagen {
  width: 100%;
  height: auto;
  border-radius: 0.5rem; }

.contacto__content {
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr 1.5fr; }
  @media screen and (max-width: 991px) {
    .contacto__content {
      display: flex;
      flex-direction: column; } }
  @media screen and (max-width: 575px) {
    .contacto__content {
      grid-template-columns: 1fr; } }

.contacto__form-priv {
  font-size: 0.7rem; }

@media screen and (max-width: 991px) {
  .contacto__col2 {
    margin: 2rem 0; } }

@media screen and (max-width: 575px) {
  .contacto__col2 {
    margin: 3rem 0 1rem 0; } }

.contacto__form--margen {
  margin-bottom: 1rem; }

@media screen and (max-width: 575px) {
  .contacto__form--margenMov {
    margin-bottom: 1rem; } }

.contacto__form a {
  color: #f88379;
  text-decoration: none; }
  .contacto__form a:hover {
    color: #f76d61; }

.contacto__boton {
  max-width: fit-content; }

.contacto__col3 {
  grid-column: 1 / span 2; }
  @media screen and (max-width: 575px) {
    .contacto__col3 {
      margin-bottom: 2rem; } }

.contacto__titulo {
  font-family: "karmina-sans", sans-serif;
  color: #dee07d;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-bottom: 0;
  font-weight: bold; }
  @media screen and (max-width: 575px) {
    .contacto__titulo {
      align-self: center;
      margin-bottom: 0; } }

.contacto__subtitulo {
  margin-bottom: 0.5rem; }

.contacto__datos {
  margin-top: 1rem;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 991px) {
    .contacto__datos {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 3rem;
      justify-content: center;
      text-align: center; } }
  @media screen and (max-width: 575px) {
    .contacto__datos {
      grid-template-columns: 1fr;
      gap: 2rem; } }

.contacto__dato {
  margin-bottom: 2rem; }
  @media screen and (max-width: 991px) {
    .contacto__dato {
      margin-bottom: 0; } }
  @media screen and (max-width: 575px) {
    .contacto__dato {
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      gap: 1rem; } }

.contacto__telefonos .contacto__info a {
  display: block; }

@media screen and (max-width: 575px) {
  .contacto__info {
    align-self: center; }
    .contacto__info p {
      margin-bottom: 0; } }

.contacto .page__entry {
  margin-top: 10px;
  margin-bottom: 3rem;
  font-size: 1.4375rem; }

.contactos {
  background-color: #e9eab6; }
  .contactos__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6rem; }
    .contactos__content h4 {
      color: #4a4a24; }
    @media screen and (max-width: 991px) {
      .contactos__content {
        width: 100%; } }
    @media screen and (max-width: 575px) {
      .contactos__content {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem 1rem; } }
  .contactos .boton {
    margin-bottom: 0.5rem;
    width: fit-content; }
  .contactos__grupo {
    display: flex; }
    .contactos__grupo .bi {
      margin-right: 0.3rem; }
  .contactos .contacto__dato {
    display: flex;
    flex-direction: column;
    margin-bottom: 0; }

@media screen and (max-width: 575px) {
  .contactos__col:nth-child(3) {
    grid-column: 1 / span 2; } }

.mapa iframe {
  height: 500px;
  width: 100%; }

.mapa__titulo {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.5rem; }

.mapa__numeros ul {
  list-style: none;
  padding-left: 0px; }

.mapa__numeros li span {
  font-weight: bold;
  display: inline-block;
  width: 120px; }

.mapa__mail span {
  font-weight: bold;
  display: inline-block;
  width: 120px; }

.mapa__direccion span {
  font-weight: bold;
  display: inline-block;
  width: 120px; }

.gblog__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem 2rem; }
  @media screen and (max-width: 991px) {
    .gblog__content {
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem; } }
  @media screen and (max-width: 575px) {
    .gblog__content {
      grid-template-columns: 1fr;
      gap: 4rem; } }

.gblog__thumb {
  display: block;
  margin-bottom: 1rem; }

.gblog__img {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07); }

.gblog__time {
  margin-bottom: 0.5rem;
  text-transform: uppercase; }

.gblog__tax {
  font-size: 0.8rem;
  text-transform: uppercase; }
  .gblog__tax a {
    color: #4a4a24;
    font-weight: bold; }

.gblog__titulo {
  font-size: 1.8rem;
  font-weight: bold; }

.gblog__descripcion {
  align-self: center; }

.blog__item:nth-last-child(1) {
  margin-bottom: 0; }

.entrada__content {
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  gap: 6rem; }
  @media screen and (max-width: 991px) {
    .entrada__content {
      display: flex;
      gap: 0;
      flex-direction: column; } }

@media screen and (max-width: 991px) {
  .entrada__left {
    margin-bottom: 2rem; } }

.entrada__img {
  width: 100%;
  height: auto;
  border-radius: 0.5rem; }

.entrada__titulo {
  margin-top: 0;
  padding-top: 0;
  color: #3c3c3b;
  font-size: 2.5rem !important;
  font-weight: bold;
  margin-bottom: 2rem; }
  @media (max-width: 575px) {
    .entrada__titulo {
      font-size: 1.8rem !important;
      margin-bottom: 1rem; } }

.entrada .page__entry {
  font-size: 1.25rem; }

.servicios-medicos__content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 3rem; }
  @media screen and (max-width: 991px) {
    .servicios-medicos__content {
      grid-template-columns: 1fr;
      gap: 0; } }

@media screen and (max-width: 991px) {
  .servicios-medicos__left {
    padding-bottom: 7rem; } }

@media screen and (max-width: 575px) {
  .servicios-medicos__left {
    padding-bottom: 3rem; } }

.servicios-medicos__subtitulo {
  font-family: "karmina-sans", sans-serif;
  font-size: 1.375rem;
  font-weight: 400; }

.servicios-medicos__listado .accordion-button {
  background-color: #e9eab6;
  border-radius: 0.5rem !important;
  transition: 0.3s all ease-in-out; }

.servicios-medicos__listado .accordion-button:not(.collapsed) {
  color: #3c3c3b;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  transition: 0.3s all ease-in-out; }
  .servicios-medicos__listado .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3e%3c/svg%3e"); }

.servicios-medicos__listado .accordion-button::after {
  width: 2rem;
  height: 2rem;
  background-size: 2rem;
  background-color: white;
  border-radius: 16px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3e%3c/svg%3e"); }

.servicios-medicos__listado .accordion-button:focus {
  box-shadow: none; }

.servicios-medicos__servicio {
  margin-bottom: 2rem;
  border: none;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07);
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important; }

.servicios-medicos__nombre button {
  font-size: 1.5625rem; }

.servicios-medicos__descripcion {
  font-size: 1.25rem;
  padding: 2rem; }
  .servicios-medicos__descripcion p {
    margin: 0; }

.estetica-cirugia__content .titulos {
  max-width: 55%; }
  @media screen and (max-width: 991px) {
    .estetica-cirugia__content .titulos {
      max-width: 100%; } }

.estetica-cirugia__tipo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10rem; }
  @media screen and (max-width: 991px) {
    .estetica-cirugia__tipo {
      gap: 1rem;
      grid-template-columns: 1fr; } }

@media screen and (max-width: 991px) {
  .estetica-cirugia__left {
    order: 2; } }

@media screen and (max-width: 991px) {
  .estetica-cirugia__right {
    order: 1; } }

.estetica-cirugia__subtitulo {
  font-family: "karmina-sans", sans-serif;
  font-size: 1.375rem;
  font-weight: 400; }

.estetica-cirugia__tratamientos {
  display: flex;
  flex-wrap: wrap; }

.estetica-cirugia__tratamiento {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  border: 1px solid #f8d3c3;
  background-color: #f8d3c3;
  color: #3c3c3b;
  padding: 0.6rem 1.5rem;
  border-radius: 0rem;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07); }
  .estetica-cirugia__tratamiento a {
    color: #3c3c3b;
    text-decoration: none; }
  @media screen and (max-width: 575px) {
    .estetica-cirugia__tratamiento {
      padding: 0.2rem 0.5rem;
      font-size: 0.9rem; } }

.estetica-cirugia__img {
  width: 100%;
  height: auto;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem; }

.estetica-cirugia__tipo:nth-child(1) {
  margin-top: 0; }

.estetica-cirugia__tipo:nth-child(even) .estetica-cirugia__left {
  order: 2; }

.estetica-cirugia__tipo:nth-child(even) .estetica-cirugia__right {
  order: 1; }
  @media screen and (max-width: 991px) {
    .estetica-cirugia__tipo:nth-child(even) .estetica-cirugia__right {
      order: 1; } }

@media screen and (max-width: 991px) {
  .centro-estetico {
    padding-bottom: 0rem; } }

.centro-estetico__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 5rem; }
  @media screen and (max-width: 991px) {
    .centro-estetico__content {
      display: flex;
      flex-direction: column;
      gap: 0; } }

.centro-estetico__descripcion {
  font-family: "karmina-sans", sans-serif;
  font-size: 1.375rem;
  font-weight: 400;
  margin-bottom: 3rem; }

.centro-estetico__imagen-principal img {
  width: 100%;
  height: auto;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem; }

@media screen and (max-width: 991px) {
  .galeria {
    padding-top: 0.5rem; } }

.galeria__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem; }
  @media screen and (max-width: 991px) {
    .galeria__content {
      gap: 0.5rem; } }
  @media screen and (max-width: 575px) {
    .galeria__content {
      grid-template-columns: repeat(2, 1fr); } }

.galeria__img {
  width: 100%;
  height: auto;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07);
  border-radius: 0.5rem; }

.cuadro-medico__encabezado {
  max-width: 50%; }
  @media screen and (max-width: 991px) {
    .cuadro-medico__encabezado {
      max-width: 100%; } }

.cuadro-medico__subtitulo {
  max-width: 100%; }

.cuadro-medico__listado {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem 6rem; }
  @media screen and (max-width: 991px) {
    .cuadro-medico__listado {
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem 4rem; } }
  @media screen and (max-width: 575px) {
    .cuadro-medico__listado {
      grid-template-columns: 1fr;
      gap: 3rem; } }

.cuadro-medico__especialista {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }

.cuadro-medico__img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem; }

.cuadro-medico__nombre {
  margin: 0.5rem 0;
  font-size: 1.875rem;
  color: #3c3c3b !important; }

.cuadro-medico__tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.cuadro-medico__especialidad {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  border: 1px solid #e9eab6;
  background-color: #e9eab6;
  color: #3c3c3b;
  padding: 2px 7px;
  border-radius: 0rem;
  font-weight: 600; }
  .cuadro-medico__especialidad a {
    color: #3c3c3b;
    text-decoration: none; }
  @media screen and (max-width: 575px) {
    .cuadro-medico__especialidad {
      padding: 0.2rem 0.5rem;
      font-size: 0.9rem; } }

.cuadro-medico__numero {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  color: #888888;
  font-weight: 600; }
  .cuadro-medico__numero a {
    color: #3c3c3b;
    text-decoration: none; }
  @media screen and (max-width: 575px) {
    .cuadro-medico__numero {
      font-size: 0.9rem; } }

.page-id-918 .cuadro-medico__especialidad {
  border: 1px solid #f8d3c3;
  background-color: #f8d3c3; }

.page-id-919 .cuadro-medico__especialidad {
  border: 1px solid #dee07d;
  background-color: #dee07d; }

.single-medico__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10rem;
  font-size: 1.25rem; }
  @media screen and (max-width: 991px) {
    .single-medico__content {
      gap: 3rem; } }
  @media screen and (max-width: 575px) {
    .single-medico__content {
      grid-template-columns: 1fr; } }

.single-medico__img {
  width: 100%;
  height: auto; }

.single-medico__tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.4rem;
  margin-bottom: 1rem; }

.single-medico__especialidad::after {
  content: ",\0000a0"; }

.single-medico__right ul {
  list-style: none;
  padding-left: 1rem; }

.single-medico__right li {
  vertical-align: middle;
  margin-bottom: 1rem;
  margin-left: 4px; }
  .single-medico__right li::before {
    content: "\002B";
    color: #dddf8e;
    font-weight: bold;
    display: inline-block;
    width: 1.3rem;
    margin-left: -1.3rem;
    font-size: 1.5rem; }

.single-medico__especialidad:nth-last-child(2)::after {
  content: " y\0000a0"; }

.single-medico__especialidad:nth-last-child(1)::after {
  content: "."; }

.single-servicio__content {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 10rem; }
  @media screen and (max-width: 991px) {
    .single-servicio__content {
      grid-template-columns: 1fr;
      gap: 6rem; } }

.single-servicio__left {
  font-size: 1.25rem; }
  .single-servicio__left h2 {
    font-size: 2.5rem; }

.single-servicio__img {
  width: 100%;
  height: auto;
  margin-bottom: 2.8rem; }

.relacionados__listado {
  display: flex;
  flex-wrap: wrap; }

.relacionados__item {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  border: 1px solid #f8d3c3;
  background-color: #f8d3c3;
  color: #3c3c3b;
  padding: 0.75rem 2rem;
  border-radius: 0rem;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07); }
  .relacionados__item a {
    color: #3c3c3b;
    text-decoration: none; }
  @media screen and (max-width: 575px) {
    .relacionados__item {
      padding: 0.2rem 0.5rem;
      font-size: 0.8rem; } }

.citas__content {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 10rem; }
  @media screen and (max-width: 991px) {
    .citas__content {
      display: flex;
      flex-direction: column;
      gap: 0; } }

@media screen and (max-width: 991px) {
  .citas__left {
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 0; } }

.citas__instruccion {
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 2rem;
  margin-bottom: 2rem; }
  @media screen and (max-width: 991px) {
    .citas__instruccion {
      grid-template-columns: 1fr 4fr; } }
  @media screen and (max-width: 575px) {
    .citas__instruccion {
      grid-template-columns: 1fr 4fr; } }

.citas__instruccion-numero {
  background-color: #f88379;
  border-radius: 32px;
  width: 4rem;
  height: 4rem;
  font-size: 3rem;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 991px) {
    .citas__instruccion-numero {
      justify-self: center;
      align-self: start; } }

.citas__instruccion-descripcion h2 {
  font-weight: bold;
  font-size: 2.185rem;
  margin-bottom: 1rem; }
  @media screen and (max-width: 575px) {
    .citas__instruccion-descripcion h2 {
      font-size: 1.875rem;
      margin-bottom: 0.75rem; } }

.citas__instruccion-descripcion h5 {
  font-size: 1.375rem;
  line-height: 1.2;
  font-family: "karmina-sans", sans-serif; }
  @media screen and (max-width: 575px) {
    .citas__instruccion-descripcion h5 {
      font-size: 1.2rem; } }

.citas__right {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 575px) {
    .citas__right iframe {
      height: 770px !important; } }

.citas .tt-booking-button-container-customer button.tt-booking-button-customer {
  display: inline-block !important;
  padding: 12px 30px !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 20px !important;
  transition: 0.2s all ease-in-out !important;
  text-decoration: none !important;
  border-radius: 2px !important;
  border: 2px solid !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  -ms-font-smoothing: antialiased !important;
  -o-font-smoothing: antialiased !important;
  font-smooth: antialiased !important;
  background-color: #f88379 !important;
  color: white !important;
  border-color: #f88379 !important;
  width: 100% !important; }
  .citas .tt-booking-button-container-customer button.tt-booking-button-customer:hover {
    background-color: #fbb0a9 !important;
    border-color: #fbb0a9 !important;
    color: white !important; }
  @media screen and (max-width: 991px) {
    .citas .tt-booking-button-container-customer button.tt-booking-button-customer {
      padding: 10px 22px !important; } }

.citas .tt-booking-button-customer.boton-citas {
  background-color: #f88379 !important; }

.page-id-13 .citas__instruccion-numero {
  background-color: #dee07d !important; }

.p-seguros__content {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 3rem; }
  @media screen and (max-width: 991px) {
    .p-seguros__content {
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem; } }
  @media screen and (max-width: 575px) {
    .p-seguros__content {
      grid-template-columns: repeat(2, 1fr); } }

.p-seguros__img {
  width: 100%;
  height: 156px;
  object-fit: cover; }
  @media screen and (max-width: 991px) {
    .p-seguros__img {
      height: 106px; } }
  @media screen and (max-width: 575px) {
    .p-seguros__img {
      height: 96px; } }

.pro-claim {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 4rem; }
  @media screen and (max-width: 767px) {
    .pro-claim {
      grid-template-columns: 1fr;
      gap: 2rem; } }
  .pro-claim__logo {
    max-width: 300px;
    height: auto;
    margin-bottom: 2rem; }
  .pro-claim__silla img {
    width: 100%;
    height: auto; }
    @media screen and (max-width: 767px) {
      .pro-claim__silla img {
        width: 50%; } }
  .pro-claim__descripcion {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .pro-claim__descripcion h3 {
      font-weight: bold;
      margin-bottom: 1.5rem; }

.pro-descripcion {
  text-align: center;
  font-family: "karmina", serif;
  font-weight: bold;
  color: #f88379; }
  .pro-descripcion span {
    font-weight: normal;
    font-family: "karmina-sans", sans-serif;
    display: block;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 2rem;
    font-size: 80%;
    width: 80%;
    margin-left: auto;
    margin-right: auto; }
    @media screen and (max-width: 991px) {
      .pro-descripcion span {
        width: 100%;
        margin-top: 1rem; } }
  @media screen and (max-width: 575px) {
    .pro-descripcion {
      font-size: 1.5rem; } }

.pro-tratamientos__item {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 4rem;
  margin-bottom: 5rem; }
  @media screen and (max-width: 991px) {
    .pro-tratamientos__item {
      grid-template-columns: 1fr;
      gap: 2rem; } }
  @media screen and (max-width: 767px) {
    .pro-tratamientos__item {
      gap: 1rem; } }
  .pro-tratamientos__item:last-child {
    margin-bottom: 0 !important; }

.pro-tratamientos__imagen img {
  width: 100%;
  height: auto; }

.pro-tratamientos__titulo {
  margin-bottom: 1.5rem;
  color: #4a4a24;
  font-weight: bold;
  font-size: 2.7rem; }

.pro-tratamientos__descripcion h3 {
  font-size: 1.2rem;
  font-weight: bold; }

.faq__logo {
  margin-bottom: 4rem;
  text-align: center; }
  @media screen and (max-width: 991px) {
    .faq__logo {
      text-align: left; } }
  .faq__logo img {
    width: 250px;
    height: auto; }

.faq__content {
  color: white; }

.faq__item {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 4rem;
  margin-bottom: 4rem; }
  @media screen and (max-width: 991px) {
    .faq__item {
      grid-template-columns: 1fr;
      gap: 2rem; } }

.faq__titulo {
  text-align: right; }
  @media screen and (max-width: 991px) {
    .faq__titulo {
      text-align: left; } }

#submit_bizum_payment_form,
#submit_redsys_payment_form {
  font-size: 0.9rem;
  background-color: #f88379;
  color: white;
  margin-right: 1rem;
  box-shadow: none;
  width: auto !important;
  padding: 0.625rem 1.875rem;
  border: 2px;
  border-radius: 5px;
  letter-spacing: 1px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center;
  display: inline-flex !important; }
  @media (max-width: 575px) {
    #submit_bizum_payment_form,
    #submit_redsys_payment_form {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    #submit_bizum_payment_form,
    #submit_redsys_payment_form {
      padding: 0.7rem 1rem;
      font-size: 0.8rem; } }
  #submit_bizum_payment_form .bi,
  #submit_redsys_payment_form .bi {
    margin-left: 5px; }
  #submit_bizum_payment_form:hover,
  #submit_redsys_payment_form:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    transition: 0.2s all ease-in-out;
    background-color: #fbb0a9;
    color: white; }

.button.cancel {
  background-color: #4a4a24 !important;
  color: white !important; }
  .button.cancel:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    transition: 0.2s all ease-in-out;
    background-color: #6c6c35 !important;
    color: white !important; }

.submit,
.button {
  font-size: 0.9rem;
  background-color: #f88379 !important;
  color: white !important;
  display: inline;
  box-shadow: none;
  padding: 0.625rem 1.875rem;
  border: 2px;
  border-radius: 5px;
  letter-spacing: 1px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center; }
  @media (max-width: 575px) {
    .submit,
    .button {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    .submit,
    .button {
      padding: 0.7rem 1rem;
      font-size: 0.8rem; } }
  .submit .bi,
  .button .bi {
    margin-left: 5px; }
  .submit:hover,
  .button:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    transition: 0.2s all ease-in-out;
    background-color: #fbb0a9 !important;
    color: white; }

.single_add_to_cart_button {
  padding: 1.1rem 2rem 1rem !important;
  display: flex;
  align-items: center;
  height: auto;
  font-size: 1.1rem !important;
  letter-spacing: 0; }
  @media (max-width: 575px) {
    .single_add_to_cart_button {
      font-size: 0.9rem !important;
      padding: 0.8rem 1rem !important; } }
  @media (max-width: 575px) {
    .single_add_to_cart_button::before {
      font-size: 1rem;
      margin-right: 0.6rem; } }
  .single_add_to_cart_button:hover {
    background-color: #888888 !important; }

form.cart button[type="submit"]:not(#qib_id):not(_):not(_) {
  height: auto !important; }

@media screen and (max-width: 357px) {
  form.cart {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; } }

.single_add_to_cart_button.disabled {
  background-color: white;
  cursor: not-allowed; }
  .single_add_to_cart_button.disabled:hover {
    background-color: white !important; }

.checkout-button,
.single_add_to_cart_button {
  background-color: #f88379;
  font-weight: 700; }
  .checkout-button:hover,
  .single_add_to_cart_button:hover {
    background-color: #fbb0a9 !important;
    color: white; }

.button[aria-disabled="false"] {
  border: 0; }

.button[aria-disabled="true"] {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.2);
  border: 0;
  cursor: not-allowed; }

.page-id-2128 .woocommerce {
  display: grid;
  grid-template-columns: 3.3fr 2fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0; }
  @media (max-width: 991px) {
    .page-id-2128 .woocommerce {
      display: block; } }
  .page-id-2128 .woocommerce .woocommerce-notices-wrapper {
    grid-column: 1 / 3; }

.cart_totals,
.shop_table {
  width: 100%; }

/* Tabla del carrito de la compra CELDAS INDIVIDUALES */
.woocommerce-cart-form {
  margin-bottom: 2rem; }
  .woocommerce-cart-form .shop_table {
    width: 100%;
    box-shadow: none !important; }
  .woocommerce-cart-form .product-remove {
    width: 40px;
    font-size: 1.2rem;
    text-align: center; }
    @media (max-width: 767px) {
      .woocommerce-cart-form .product-remove::before {
        display: none; } }
  .woocommerce-cart-form .product-remove a {
    font-size: 1.5rem;
    color: rgba(129, 13, 13, 0.774) !important; }
  .woocommerce-cart-form .product-thumbnail {
    display: flex;
    align-items: center;
    height: 100%; }
  .woocommerce-cart-form .product-thumbnail a {
    display: flex; }
  .woocommerce-cart-form .product-thumbnail img {
    width: 50px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px; }
  .woocommerce-cart-form .product-name {
    width: 50%;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1rem !important; }
    .woocommerce-cart-form .product-name .variation {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 5px 10px;
      font-size: 12px;
      margin-top: 1rem;
      margin-bottom: 0px;
      font-family: "karmina", serif; }
      @media screen and (max-width: 767px) {
        .woocommerce-cart-form .product-name .variation {
          grid-template-columns: 3fr 1fr; } }
      .woocommerce-cart-form .product-name .variation dt {
        margin-right: 5px;
        font-weight: normal;
        margin-bottom: 3px; }
      .woocommerce-cart-form .product-name .variation dd {
        margin-left: 0;
        margin-right: 5px;
        margin-bottom: 3px; }
        .woocommerce-cart-form .product-name .variation dd p {
          margin: 0;
          display: inline-flex; }
  .woocommerce-cart-form .product-price {
    text-align: right; }
  .woocommerce-cart-form .product-quantity {
    text-align: right; }
    .woocommerce-cart-form .product-quantity .qty {
      padding: 9px 2px; }
  .woocommerce-cart-form .product-subtotal {
    font-weight: bold;
    text-align: right; }
    .woocommerce-cart-form .product-subtotal .tax_label {
      font-size: 11px !important;
      font-weight: normal;
      display: block; }
  .woocommerce-cart-form thead tr th {
    height: 3rem;
    line-height: 3rem;
    font-size: 0.8rem !important;
    text-transform: uppercase; }

/* Tabla del carrito de la compra -------------------------- */
@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table thead {
    display: none; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table tbody tr {
    display: flex;
    flex-direction: column; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table tbody td {
    display: block;
    width: 100%;
    text-align: right;
    font-size: 0.9rem; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table tbody td.product-thumbnail {
    display: none; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table .cart_item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 2px;
    margin-bottom: 10px; } }

.woocommerce-cart-form table.shop_table .cart_item td {
  border-bottom: 2px solid white;
  background-color: rgba(0, 0, 0, 0.03); }

.woocommerce-cart-form table.shop_table td {
  padding: 20px 9px;
  vertical-align: middle;
  line-height: 1.5em; }
  @media screen and (max-width: 575px) {
    .woocommerce-cart-form table.shop_table td {
      padding: 5px 9px; } }

.woocommerce-cart-form table.shop_table td a {
  color: #f88379; }

.woocommerce-cart-form table.shop_table th {
  font-weight: 700;
  padding: 7px 9px;
  line-height: 1.5em; }

.woocommerce-cart-form table.shop_table .actions {
  padding: 1rem 0 0 0; }
  @media (max-width: 575px) {
    .woocommerce-cart-form table.shop_table .actions::before {
      display: none; } }

.coupon {
  width: 100%;
  margin-bottom: 0.5rem; }
  .coupon label {
    display: none; }
  @media (max-width: 767px) {
    .coupon {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1rem; } }
  @media (max-width: 767px) {
    .coupon input {
      margin-right: 10px;
      width: 50%; } }
  .coupon button {
    padding: 12px 15px; }

/* Atributos en tabla para responsive --------------- */
@media (max-width: 767px) {
  .woocommerce table.shop_table_responsive tr td::before,
  .woocommerce-page table.shop_table_responsive tr td::before {
    content: attr(data-title) ": ";
    font-weight: 400;
    float: left; } }

.cart_totals table.shop_table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-radius: 5px; }

.woocommerce table.shop_table .screen-reader-text {
  display: none; }

.update_cart {
  float: right; }

.cart-collaterals {
  width: 100%;
  display: flex;
  justify-content: right; }

/* CARRITO TOTALES Y TRANSPORTE ------------------- */
.cart_totals {
  display: block; }
  @media (max-width: 767px) {
    .cart_totals {
      width: 100%;
      font-size: 0.9rem !important; } }
  .cart_totals h2 {
    font-size: 1.5rem !important; }
  .cart_totals .shop_table {
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
    border-radius: 3px; }
  .cart_totals tbody tr td,
  .cart_totals tbody tr th {
    padding: 12px 12px !important; }
  .cart_totals tbody tr td {
    border: 1px solid rgba(0, 0, 0, 0.05); }
    .cart_totals tbody tr td::before {
      display: none; }
  .cart_totals tbody tr th {
    font-size: 0.7rem;
    text-align: right;
    width: 25%; }
    @media (max-width: 767px) {
      .cart_totals tbody tr th {
        width: 25%; } }
  .cart_totals tbody tr th:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.05); }
  .cart_totals h2 {
    margin-top: 0; }
  .cart_totals .checkout-button {
    display: block;
    font-size: 1.3rem;
    text-align: center;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }

.woocommerce-shipping-methods {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 5px !important; }
  .woocommerce-shipping-methods li label {
    font-weight: bold; }
  .woocommerce-shipping-methods li label span {
    font-weight: normal; }
  .woocommerce-shipping-methods li .betrs_option_desc {
    font-size: 0.7rem; }

.woocommerce #content table.cart td.actions,
.woocommerce table.cart td.actions,
.woocommerce-page #content table.cart td.actions,
.woocommerce-page table.cart td.actions {
  text-align: right; }

.woocommerce #content table.cart td.actions .coupon,
.woocommerce table.cart td.actions .coupon,
.woocommerce-page #content table.cart td.actions .coupon,
.woocommerce-page table.cart td.actions .coupon {
  float: left; }

.woocommerce-form-coupon-toggle {
  margin-bottom: 1rem; }

.col2-set {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem; }
  @media (max-width: 767px) {
    .col2-set {
      grid-template-columns: 1fr;
      grid-gap: 2rem; } }
  .col2-set .col-1 {
    margin-bottom: 2rem; }
  .col2-set .col-2,
  .col2-set .col-1 {
    width: 100%; }

#order_review_heading,
.woocommerce-billing-fields h3,
.woocommerce-billing-fields h3 label span,
.woocommerce-additional-fields {
  font-size: 1rem !important;
  margin-top: 0;
  color: #f88379 !important;
  margin-bottom: 2rem;
  font-weight: 700; }

.woocommerce-address-fields label,
.woocommerce-checkout label {
  display: block;
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 0.9rem; }

.woocommerce-address-fields .woocommerce-input-wrapper,
.woocommerce-checkout .woocommerce-input-wrapper {
  width: 100%; }

.woocommerce-address-fields input,
.woocommerce-address-fields textarea,
.woocommerce-checkout input,
.woocommerce-checkout textarea {
  display: block;
  width: 100%; }

.form-row-wide {
  margin-top: 0 !important; }

@media (min-width: 992px) {
  #billing_phone_field,
  #billing_postcode_field,
  .form-row-first {
    width: 48%;
    display: inline-block;
    margin-right: 4%; } }

@media (min-width: 992px) {
  #billing_email_field,
  #billing_city_field,
  .form-row-last {
    width: 48%;
    display: inline-block; } }

/* checkout ---------------------------------- */
@media (min-width: 992px) {
  form.woocommerce-checkout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 3rem;
    row-gap: 1rem; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #customer_details {
    grid-column: 1 / 2;
    grid-row: 1 / 3; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #order_review_heading {
    grid-column: 2 / 3;
    grid-row: 1 / 2; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #order_review {
    grid-column: 2 / 3;
    grid-row: 2 / 3; } }

@media (min-width: 992px) {
  #customer_details.col2-set {
    display: flex;
    flex-direction: column;
    gap: 0; } }

/* checkout ---------------------------------- */
.woocommerce-form__label-for-checkbox .woocommerce-terms-and-conditions-checkbox-text {
  display: inline;
  font-size: 1rem; }

.woocommerce-form__label-for-checkbox .woocommerce-form__input-checkbox {
  width: auto;
  margin-right: 10px;
  margin-top: 5px;
  display: inline; }

.woocommerce-table--order-details,
.woocommerce-checkout-review-order .shop_table {
  padding: 1.4rem !important;
  margin-bottom: 3rem; }

.woocommerce-table--order-details thead tr th,
.woocommerce-checkout-review-order-table thead tr th {
  text-align: left;
  padding: 9px 12px;
  font-size: 1.3rem; }
  @media (max-width: 767px) {
    .woocommerce-table--order-details thead tr th,
    .woocommerce-checkout-review-order-table thead tr th {
      padding: 5px 8px; } }

.woocommerce-table--order-details tfoot tr th,
.woocommerce-table--order-details tfoot tr td,
.woocommerce-table--order-details tbody tr td,
.woocommerce-checkout-review-order-table tfoot tr th,
.woocommerce-checkout-review-order-table tfoot tr td,
.woocommerce-checkout-review-order-table tbody tr td {
  padding: 9px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: left;
  font-size: 0.8rem; }
  @media (max-width: 767px) {
    .woocommerce-table--order-details tfoot tr th,
    .woocommerce-table--order-details tfoot tr td,
    .woocommerce-table--order-details tbody tr td,
    .woocommerce-checkout-review-order-table tfoot tr th,
    .woocommerce-checkout-review-order-table tfoot tr td,
    .woocommerce-checkout-review-order-table tbody tr td {
      padding: 5px 8px !important; } }

.woocommerce-checkout-review-order-table {
  font-size: 0.9rem; }
  .woocommerce-checkout-review-order-table thead .product-total,
  .woocommerce-checkout-review-order-table thead .product-name {
    font-size: 1.1rem; }

.woocommerce-checkout-payment {
  border: 1px solid #1a191e;
  padding: 2rem;
  border-radius: 5px; }
  @media (max-width: 767px) {
    .woocommerce-checkout-payment {
      padding: 1rem; } }
  .woocommerce-checkout-payment ul.wc_payment_methods {
    list-style: none;
    padding-left: 0;
    margin-bottom: 2rem !important; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method {
      padding-right: 1rem;
      padding-bottom: 0.5rem; }
      @media screen and (max-width: 575px) {
        .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method {
          padding-bottom: 0.7rem;
          padding-right: 0; } }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label {
      font-weight: 700;
      display: inline; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label .about_paypal {
      font-weight: 400; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label > img {
      margin-left: 10px;
      margin-right: 10px; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method.payment_method_bizum img {
      width: 80px; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method img {
      max-width: 35px !important;
      height: auto; }
      .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method img:first-child {
        margin-left: 10px !important; }
    .woocommerce-checkout-payment ul.wc_payment_methods .input-radio {
      display: inline-block;
      width: auto;
      margin-right: 0.5rem; }
    .woocommerce-checkout-payment ul.wc_payment_methods .payment_box {
      width: 100%;
      margin: 10px 1.5rem 10px 1.5rem;
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      border-radius: 0.5rem;
      background-color: white;
      border: 1px solid #0c0b0d; }
      @media screen and (max-width: 575px) {
        .woocommerce-checkout-payment ul.wc_payment_methods .payment_box {
          margin-left: 0;
          margin-right: 0; } }
      .woocommerce-checkout-payment ul.wc_payment_methods .payment_box p {
        margin-bottom: 5px; }
    .woocommerce-checkout-payment ul.wc_payment_methods label {
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      font-size: 0.8rem; }

@media screen and (max-width: 575px) {
  .payment_method_stripe {
    margin: 0rem !important;
    padding: 0rem !important; } }

.payment_method_stripe label[for="payment_method_stripe"] {
  width: 90% !important; }

.place-order button.button {
  background-color: #f88379 !important;
  text-align: center;
  padding: 1.2rem 1.5rem;
  font-size: 1.2rem; }
  .place-order button.button:hover {
    background-color: #fbb0a9 !important;
    color: white !important; }
  @media (max-width: 767px) {
    .place-order button.button {
      display: block !important;
      width: 100% !important; } }
  @media (min-width: 991px) {
    .place-order button.button {
      display: block !important;
      width: 100% !important; } }

.woocommerce-terms-and-conditions-wrapper .woocommerce-privacy-policy-text {
  font-size: 0.8rem; }
  .woocommerce-terms-and-conditions-wrapper .woocommerce-privacy-policy-text p {
    font-size: 0.8rem;
    margin-bottom: 5px; }

.woocommerce-terms-and-conditions-wrapper a {
  color: #f88379; }

.woocommerce-terms-and-conditions-checkbox-text {
  font-size: 1rem; }

/* Títulos ------------------------- */
.cart-empty,
.woocommerce-thankyou-order-received,
h2.wc-bacs-bank-details-heading,
h2.woocommerce-order-details__title {
  margin-top: 0;
  padding-top: 1rem;
  font-size: 2rem !important;
  color: #f88379;
  font-weight: bold;
  margin-bottom: 1rem; }
  @media (max-width: 767px) {
    .cart-empty,
    .woocommerce-thankyou-order-received,
    h2.wc-bacs-bank-details-heading,
    h2.woocommerce-order-details__title {
      font-size: 1.3rem !important; } }

.wc-bacs-bank-details-account-name {
  margin-top: 0; }

/* Margin bottoms ------------------------- */
.order_details,
.woocommerce-bacs-bank-details {
  margin-bottom: 3rem !important; }

.order_details,
.wc-bacs-bank-details,
.woocommerce-order-overview {
  list-style: none;
  padding: 0;
  background-color: white;
  padding: 1.5rem 1rem 1rem 1.5rem;
  display: flex;
  font-size: 0.9rem;
  border: 1px solid #595959;
  border-radius: 0.5rem; }
  @media (max-width: 767px) {
    .order_details,
    .wc-bacs-bank-details,
    .woocommerce-order-overview {
      flex-direction: column;
      padding: 1rem; } }
  .order_details li,
  .wc-bacs-bank-details li,
  .woocommerce-order-overview li {
    margin-right: 1.5rem;
    padding-right: 1.5rem;
    border-right: 1px solid #595959; }
    @media (max-width: 767px) {
      .order_details li,
      .wc-bacs-bank-details li,
      .woocommerce-order-overview li {
        border-right: 0; } }
  @media (max-width: 767px) {
    .order_details li:last-child,
    .wc-bacs-bank-details li:last-child,
    .woocommerce-order-overview li:last-child {
      margin-bottom: 0 !important; } }
  .order_details li strong,
  .wc-bacs-bank-details li strong,
  .woocommerce-order-overview li strong {
    display: block; }

/* Order recibed ------------------------- */
.woocommerce-table--order-details {
  display: block !important;
  box-shadow: initial; }

#nxshop_spot_selection {
  background-color: #f8d3c3;
  color: #fff; }
  @media (max-width: 767px) {
    #nxshop_spot_selection {
      display: block;
      text-align: center; } }

#nxshop_table_container {
  font-size: 0.85rem;
  margin-top: 1rem; }
  #nxshop_table_container table tbody tr td {
    vertical-align: middle !important; }
    #nxshop_table_container table tbody tr td p {
      margin-bottom: 0; }

.woocommerce-account-fields .create-account {
  padding: 1rem;
  border: 1px dashed #595959;
  border-radius: 1rem;
  color: #f8d3c3; }

.woocommerce-form-login-toggle {
  margin-bottom: 2rem; }

.page-template-page-cart .woocommerce-form-login {
  margin-bottom: 2rem; }
  .page-template-page-cart .woocommerce-form-login > p {
    font-size: 0.7rem; }
  .page-template-page-cart .woocommerce-form-login input {
    display: block; }
  .page-template-page-cart .woocommerce-form-login .form-row {
    display: block;
    width: 100%; }
  .page-template-page-cart .woocommerce-form-login .woocommerce-form__label-for-checkbox {
    display: flex; }

@font-face {
  font-family: 'WooCommerce';
  src: url("../fonts/WooCommerce.eot?jj1bj4");
  src: url("../fonts/WooCommerce.eot?jj1bj4#iefix") format("embedded-opentype"), url("../fonts/WooCommerce.ttf?jj1bj4") format("truetype"), url("../fonts/WooCommerce.woff?jj1bj4") format("woff"), url("../fonts/WooCommerce.svg?jj1bj4#WooCommerce") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="wcicon-"], [class*=" wcicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'WooCommerce' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.wcicon-storefront:before {
  content: "\e900"; }

.wcicon-ccv:before {
  content: "\e604"; }

.wcicon-virtual:before {
  content: "\e000"; }

.wcicon-up-down:before {
  content: "\e022"; }

.wcicon-reports:before {
  content: "\e023"; }

.wcicon-refresh:before {
  content: "\e031"; }

.wcicon-navigation:before {
  content: "\e032"; }

.wcicon-status-fill:before {
  content: "\e03c"; }

.wcicon-contract:before {
  content: "\e004"; }

.wcicon-downloadable:before {
  content: "\e001"; }

.wcicon-plus:before {
  content: "\e007"; }

.wcicon-simple:before {
  content: "\e006"; }

.wcicon-on-hold:before {
  content: "\e033"; }

.wcicon-external:before {
  content: "\e034"; }

.wcicon-contract-2:before {
  content: "\e036"; }

.wcicon-expand-2:before {
  content: "\e035"; }

.wcicon-phone:before {
  content: "\e037"; }

.wcicon-user:before {
  content: "\e038"; }

.wcicon-status:before {
  content: "\e039"; }

.wcicon-status-pending:before {
  content: "\e012"; }

.wcicon-status-cancelled:before {
  content: "\e013"; }

.wcicon-west:before {
  content: "\e02f"; }

.wcicon-south:before {
  content: "\e02e"; }

.wcicon-mail:before {
  content: "\e02d"; }

.wcicon-inventory:before {
  content: "\e02c"; }

.wcicon-attributes:before {
  content: "\e02b"; }

.wcicon-north:before {
  content: "\e02a"; }

.wcicon-east:before {
  content: "\e029"; }

.wcicon-note:before {
  content: "\e028"; }

.wcicon-windows:before {
  content: "\e027"; }

.wcicon-user2:before {
  content: "\e026"; }

.wcicon-search-2:before {
  content: "\e025"; }

.wcicon-search:before {
  content: "\e024"; }

.wcicon-star-empty:before {
  content: "\e021"; }

.wcicon-share:before {
  content: "\e030"; }

.wcicon-phone-fill:before {
  content: "\e03b"; }

.wcicon-woo:before {
  content: "\e03d"; }

.wcicon-user-fill:before {
  content: "\e03a"; }

.wcicon-grouped:before {
  content: "\e002"; }

.wcicon-status-refunded:before {
  content: "\e014"; }

.wcicon-status-completed:before {
  content: "\e015"; }

.wcicon-variable:before {
  content: "\e003"; }

.wcicon-expand:before {
  content: "\e005"; }

.wcicon-status-failed:before {
  content: "\e016"; }

.wcicon-check:before {
  content: "\e017"; }

.wcicon-right:before {
  content: "\e008"; }

.wcicon-up:before {
  content: "\e009"; }

.wcicon-query:before {
  content: "\e018"; }

.wcicon-down:before {
  content: "\e00a"; }

.wcicon-truck-1:before {
  content: "\e019"; }

.wcicon-left:before {
  content: "\e00b"; }

.wcicon-truck-2:before {
  content: "\e01a"; }

.wcicon-image:before {
  content: "\e00c"; }

.wcicon-globe:before {
  content: "\e01b"; }

.wcicon-link:before {
  content: "\e00d"; }

.wcicon-gear:before {
  content: "\e01c"; }

.wcicon-calendar:before {
  content: "\e00e"; }

.wcicon-cart:before {
  content: "\e01d"; }

.wcicon-processing:before {
  content: "\e00f"; }

.wcicon-card:before {
  content: "\e01e"; }

.wcicon-view:before {
  content: "\e010"; }

.wcicon-stats:before {
  content: "\e01f"; }

.wcicon-status-processing:before {
  content: "\e011"; }

.wcicon-star-full:before {
  content: "\e020"; }

.wcicon-coupon:before {
  content: "\e600"; }

.wcicon-limit:before {
  content: "\e601"; }

.wcicon-restricted:before {
  content: "\e602"; }

.wcicon-edit:before {
  content: "\e603"; }

textarea,
select,
input,
.qty {
  padding: 12px 12px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 16px;
  color: #595959; }
  @media (max-width: 575px) {
    textarea,
    select,
    input,
    .qty {
      font-size: 0.7rem;
      padding: 11px 11px; } }

.qty {
  text-align: center;
  width: 80px; }

.shop_table .qty {
  width: 70px; }

select {
  background: #fff url("../../assets/ico/ico-arrow-down-pic.svg") no-repeat right center;
  background-size: 18px auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-position: right 15px top 10px;
  cursor: pointer;
  /* Hide default dropdown arrow */
  appearance: none;
  transition: 0.2s all ease-in-out;
  padding-right: 2rem !important;
  font-size: 0.8rem; }
  select.orderby {
    padding: 8px 10px;
    box-shadow: none; }
  select:hover {
    color: #f88379;
    transition: 0.2s all ease-in-out; }

.selection .select2-selection {
  height: 50px !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  box-shadow: inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15); }

.selection .select2-selection__rendered {
  padding: 10px 14px; }

.selection .select2-selection__arrow {
  padding: 10px 12px;
  margin-top: 12px;
  margin-right: 5px; }

.selection .select2-selection__clear {
  margin-right: 1rem; }

.selection .select2-selection__arrow b {
  border-width: 10px 10px 0 10px; }

.qib-container .minus {
  border-radius: 4px 0 0 4px !important; }

.qib-container .plus {
  border-radius: 0 4px 4px 0 !important; }

.grid-woo {
  display: flex; }
  .grid-woo__sidebar {
    display: block; }
    @media (max-width: 767px) {
      .grid-woo__sidebar {
        display: none; } }
  .grid-woo__sidebar-responsive {
    display: block; }
    @media (min-width: 768px) {
      .grid-woo__sidebar-responsive {
        display: none; } }
  .grid-woo__content .woocommerce-result-count {
    display: none; }
  .grid-woo__content select.orderby {
    margin-bottom: 2rem; }
    @media screen and (max-width: 991px) {
      .grid-woo__content select.orderby {
        margin-bottom: 1rem; } }
    @media screen and (max-width: 767px) {
      .grid-woo__content select.orderby {
        margin-bottom: 0; } }

.widget--woo {
  margin-bottom: 0rem; }
  @media (max-width: 575px) {
    .widget--woo {
      margin-bottom: 1rem; } }
  .widget--woo h4.widget__title {
    display: none; }
  .widget--woo input {
    box-shadow: none !important; }

.single-product .grid-woo {
  display: block; }

.single-product .grid-woo__sidebar-responsive,
.single-product .grid-woo__sidebar {
  display: none; }

.tax-product_cat .grid-woo,
.post-type-archive-product .grid-woo {
  margin-top: 2rem !important; }
  @media screen and (max-width: 767px) {
    .tax-product_cat .grid-woo,
    .post-type-archive-product .grid-woo {
      margin-top: 0.5rem !important; } }

.page-woocommerce {
  padding-top: 2rem;
  padding-bottom: 4rem; }
  .page-woocommerce--product {
    padding-top: 0; }

.term-description {
  margin-top: 2.5rem; }

.widget-tallas {
  margin-bottom: 2rem; }
  .widget-tallas__widget {
    width: 100%; }
    @media screen and (min-width: 992px) {
      .widget-tallas__widget {
        width: 30%; } }

ul.products {
  padding-left: 0;
  list-style: none;
  display: grid;
  width: 100%;
  margin-top: 1rem; }
  ul.products li.product {
    background-color: white;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    ul.products li.product .button {
      background: #888888;
      border-color: #888888;
      border-width: 2px !important;
      color: white;
      border-style: solid;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      transition: 0.2s all ease-in-out; }
      ul.products li.product .button:hover .bi {
        transition: 0.2s all ease-in-out;
        margin-right: 15px; }
      ul.products li.product .button .bi {
        margin-right: 5px;
        margin-bottom: 5px;
        transition: 0.2s all ease-in-out; }
  ul.products .product__cat {
    margin-bottom: 0.33333rem;
    color: #595959;
    font-size: 0.75rem;
    letter-spacing: 0.5px; }
    @media (max-width: 575px) {
      ul.products .product__cat {
        margin-bottom: 0.2rem !important; } }
  ul.products .woocommerce-loop-product__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    text-align: center; }
    @media (max-width: 575px) {
      ul.products .woocommerce-loop-product__link {
        align-items: center; } }
    ul.products .woocommerce-loop-product__link .onsale {
      position: absolute;
      background-color: #dee07d;
      color: white;
      padding: 3px 8px 4px;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: bold;
      top: 0.5rem;
      left: 0.5rem; }
      @media screen and (max-width: 575px) {
        ul.products .woocommerce-loop-product__link .onsale {
          font-size: 0.7rem;
          padding: 2px 8px 3px; } }
  ul.products .woocommerce-loop-product__link .wp-post-image {
    width: 100%;
    height: auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    border-radius: 4px; }
  ul.products .attachment-woocommerce_thumbnail {
    width: 100% !important;
    height: auto;
    margin-bottom: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.1); }
    @media (max-width: 575px) {
      ul.products .attachment-woocommerce_thumbnail {
        margin-bottom: 0.8rem !important; } }
  ul.products .woocommerce-loop-product__title {
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 500;
    display: block;
    color: #595959;
    font-weight: bold; }
    @media (max-width: 575px) {
      ul.products .woocommerce-loop-product__title {
        font-size: 1rem;
        font-family: "karmina-sans", sans-serif; } }
  ul.products .price .woocommerce-Price-amount {
    color: #f88379;
    font-size: 1.125rem; }
  ul.products .price ins {
    text-decoration: none !important; }
    ul.products .price ins bdi {
      color: #dee07d;
      font-size: 1.125rem;
      margin-left: 0.5rem; }

.columns-3 {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-3 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns-3 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem; } }
  @media (max-width: 480px) {
    .columns-3 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem; } }

.columns-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem 2rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3rem 1rem; } }
  @media (max-width: 575px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem 1rem; } }

.columns-5 {
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 3rem 2rem; }
  @media screen and (max-width: 1199px) {
    .columns-5 {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 2rem 1rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-5 {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 3rem 2rem; } }
  @media (max-width: 767px) {
    .columns-5 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3rem 1rem; } }
  @media (max-width: 575px) {
    .columns-5 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem 1rem; } }

@media (max-width: 575px) {
  .page-template-front-page .columns-4,
  .term-zona-outlet .columns-4 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem 1rem; } }

.columns-6 {
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-6 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns-6 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem; } }
  @media (max-width: 480px) {
    .columns-6 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem; } }

.products li.product-category img {
  width: 100%;
  height: auto;
  border: 0px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem; }

.products li.product-category h2.woocommerce-loop-category__title {
  font-size: 1rem;
  color: #1a191e;
  text-align: center; }

.term-description {
  margin-bottom: 1rem;
  font-size: 0.85rem; }

.woocommerce-result-count {
  display: inline-block;
  color: #1a191e;
  margin-top: 10px;
  font-size: 0.8rem; }
  @media (max-width: 575px) {
    .woocommerce-result-count {
      font-size: 0.8rem;
      display: none; } }

.woocommerce-ordering {
  display: inline-block;
  float: right;
  clear: both; }
  @media (max-width: 991px) {
    .woocommerce-ordering {
      float: none; } }

/* Paginador -------------------- */
.woocommerce-pagination {
  text-align: center; }
  .woocommerce-pagination ul.page-numbers {
    list-style: none;
    display: inline-flex;
    justify-content: center;
    width: auto !important;
    border: 0px solid #f88379;
    padding: 2px;
    border-radius: 3px; }
  .woocommerce-pagination li {
    display: inline-block; }
  .woocommerce-pagination li .page-numbers {
    padding: 10px 20px;
    background-color: #f88379;
    color: #fff;
    margin: 2px;
    display: block;
    border-radius: 3px;
    font-size: 1.2rem; }
  .woocommerce-pagination li .page-numbers.current {
    background-color: #f88379; }
  .woocommerce-pagination li .page-numbers:hover {
    background-color: #f88379; }

.login {
  position: relative; }
  .login__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.2); }
    @media screen and (max-width: 767px) {
      .login__overlay {
        background: rgba(0, 0, 0, 0.5); } }
  .login__container {
    position: relative; }

.page-template-page-login.logged-out .bg-login {
  background: url("../assets/site/hero-cover.jpg") center center;
  background-size: cover; }

.page-template-page-login.logged-in .login__overlay {
  background: none; }

.logged-out.page-template-page-login .woocommerce > h2 {
  display: none; }

.woocommerce-form-login {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem 2rem;
  background-color: white;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2); }

.woocommerce-form-row label,
.woocommerce-form-login label {
  display: block;
  margin-bottom: 8px;
  font-size: 0.9rem; }

.woocommerce-form-row input,
.woocommerce-form-login input {
  display: block;
  width: 100%; }

.woocommerce-form-row__rememberme,
.woocommerce-form-login__rememberme {
  padding-bottom: 1rem !important; }

.woocommerce-form-row__submit,
.woocommerce-form-login__submit {
  display: block;
  width: 100%; }

.woocommerce-form-row .lost_password,
.woocommerce-form-login .lost_password {
  font-size: 0.7rem;
  text-align: right; }

.page-template-page-login.logged-in .woocommerce {
  display: grid;
  grid-template-columns: 3fr 9fr 1fr;
  grid-gap: 3rem; }
  @media (max-width: 991px) {
    .page-template-page-login.logged-in .woocommerce {
      display: initial; } }

.woocommerce-MyAccount-navigation ul {
  list-style: none; }
  @media (max-width: 991px) {
    .woocommerce-MyAccount-navigation ul {
      display: flex;
      padding: 0;
      background-color: initial;
      margin-top: 0;
      flex-wrap: wrap; } }

.woocommerce-MyAccount-navigation li {
  margin-right: 1rem; }
  @media screen and (max-width: 991px) {
    .woocommerce-MyAccount-navigation li {
      margin-bottom: 3px; } }

@media (max-width: 991px) {
  .woocommerce-MyAccount-navigation li a {
    padding: 5px 1rem;
    background-color: #1a191e;
    border-radius: 50px;
    color: white;
    display: inline-flex; } }

@media (max-width: 767px) {
  .woocommerce-MyAccount-navigation li a {
    font-size: 0.7rem;
    color: #595959;
    color: white; } }

.woocommerce-MyAccount-navigation li.is-active a {
  color: #f8d3c3; }
  @media (max-width: 991px) {
    .woocommerce-MyAccount-navigation li.is-active a {
      font-size: 0.7rem;
      color: white;
      background-color: #dee07d; } }

.woocommerce-MyAccount-content .woocommerce-table--order-details {
  display: block; }

.woocommerce-MyAccount-content .woocommerce-Message--info {
  display: flex;
  flex-direction: column; }

.woocommerce-MyAccount-content .woocommerce-Message .button {
  display: inline-block;
  width: max-content;
  margin-bottom: 1rem; }

.woocommerce-EditAccountForm .woocommerce-form-row input {
  width: auto; }

.woocommerce-EditAccountForm span em {
  font-size: 0.7rem; }

.account-orders-table {
  font-size: 0.9rem;
  border-collapse: collapse; }
  @media (max-width: 767px) {
    .account-orders-table thead {
      display: none; } }
  @media (max-width: 767px) {
    .account-orders-table tbody {
      font-size: 0.7rem; } }
  @media (max-width: 767px) {
    .account-orders-table tbody tr {
      display: block;
      display: flex;
      flex-direction: column;
      background-color: white;
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 0.5rem; } }
  .account-orders-table tbody td {
    text-align: center;
    padding: 10px 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 0;
    border-right: 0; }
    @media (max-width: 767px) {
      .account-orders-table tbody td {
        text-align: right;
        padding: 8px 5px; } }
  .account-orders-table tbody .view {
    padding: 6px 1rem;
    display: block; }
  .account-orders-table .woocommerce-orders-table__cell-order-status {
    font-weight: bold; }
  .account-orders-table .woocommerce-orders-table__cell-order-actions .view {
    display: inline-block; }

div[data-title="Cancelado"] {
  color: #760e0e; }

.woocommerce-notices-wrapper {
  display: block;
  padding-top: 1rem;
  margin-bottom: 3rem; }
  @media screen and (max-width: 575px) {
    .woocommerce-notices-wrapper {
      margin-bottom: 1rem; } }
  .woocommerce-notices-wrapper:empty {
    margin-bottom: 0;
    padding: 0; }
  .woocommerce-notices-wrapper .woocommerce-error li {
    color: red; }
  .woocommerce-notices-wrapper .woocommerce-message {
    display: block;
    padding: 1rem;
    background-color: white;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    overflow: hidden; }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .woocommerce-message {
        display: block;
        font-size: 0.8rem;
        text-align: left;
        background-color: #faffd0;
        border: 1px solid #b5bb84;
        border-radius: 0;
        padding: 1.0rem;
        box-shadow: none;
        text-align: center; } }
    .woocommerce-notices-wrapper .woocommerce-message a.button {
      text-align: center; }
      @media (max-width: 575px) {
        .woocommerce-notices-wrapper .woocommerce-message a.button {
          float: left;
          display: inline-block;
          padding: .85rem 1rem;
          width: 100%;
          height: auto;
          margin-bottom: 0.5rem; } }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .woocommerce-message a.restore-item {
        height: auto;
        display: inline; } }
  .woocommerce-notices-wrapper .button {
    display: inline-block;
    background-color: #f88379;
    margin-right: 2rem;
    font-weight: normal; }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .button {
        width: 230px;
        margin-right: 1rem; } }
    .woocommerce-notices-wrapper .button:hover {
      color: white; }

.woocommerce-NoticeGroup-checkout .woocommerce-error li {
  color: #880f0f; }

div.product {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: auto;
  column-gap: 2.5rem;
  row-gap: 5rem;
  position: relative; }
  @media (max-width: 767px) {
    div.product {
      display: block; } }
  div.product .woocommerce-product-gallery {
    grid-column: 1 / 6;
    grid-row: 1 / 2;
    position: relative; }
  div.product .summary {
    grid-column: 6 / 10;
    grid-row: 1 / 2; }
    @media screen and (max-width: 991px) {
      div.product .summary {
        grid-column: 6 / 11; } }
  div.product .woocommerce-tabs {
    grid-column: 1 / 6;
    grid-row: 2 / 3; }
  div.product .related {
    grid-column: 6 / 11;
    grid-row: 2 / 3; }
  div.product .up-sells {
    grid-column: 1 / 11;
    grid-row: 3 / 4; }

@media (max-width: 767px) {
  .summary {
    margin-bottom: 3rem !important; } }

@media screen and (max-width: 575px) {
  .summary {
    margin-bottom: 3rem; } }

.summary .product_title {
  margin-top: 0;
  padding-top: 0;
  color: #3c3c3b;
  font-size: 2.5rem !important;
  font-weight: bold;
  margin-bottom: 0rem; }
  @media (max-width: 575px) {
    .summary .product_title {
      font-size: 2rem !important;
      margin-bottom: 0.3rem;
      font-family: "karmina-sans", sans-serif; } }

.summary .price {
  font-size: 1.5rem !important; }
  @media (max-width: 575px) {
    .summary .price {
      font-size: 1.2rem !important; } }

.summary ins {
  text-decoration: none !important; }
  .summary ins bdi {
    color: red; }

.summary del {
  margin-right: 0.5rem; }
  .summary del bdi {
    font-size: 80%; }

.summary .screen-reader-text {
  display: none; }

.summary .cart.variations_form {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem; }

.summary .cart {
  margin-bottom: 2rem; }

.summary .cart .qty {
  padding: 15px 10px;
  font-size: 1rem;
  text-align: center;
  box-shadow: none; }
  @media (max-width: 575px) {
    .summary .cart .qty {
      padding: 10px 5px;
      width: 80px; } }

.summary .quantity {
  margin-right: 1rem; }
  .summary .quantity.hidden {
    display: none; }

.summary .single_add_to_cart_button {
  display: flex;
  justify-content: center;
  width: 100%; }

.summary .woocommerce-variation-add-to-cart {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.ncdgw_textarea_wrapper {
  margin-top: 0.5rem; }

.product_meta {
  font-size: 0.8rem;
  margin-bottom: 1rem;
  font-family: neue-haas-grotesk-text, sans-serif;
  display: none; }
  .product_meta > span {
    display: block; }
  .product_meta a {
    color: #888888;
    text-decoration: underline; }

.summary .price {
  margin: 0 0 1rem;
  font-weight: normal;
  color: #888888;
  font-size: 2rem; }
  @media (max-width: 575px) {
    .summary .price {
      border: none;
      margin: 0 0 1rem;
      padding: 0; } }
  @media (max-width: 390px) {
    .summary .price {
      margin: 0 0 0.5rem; } }

.summary .woocommerce-product-details__short-description {
  margin-bottom: 1.4rem;
  font-size: 1rem;
  color: #595959;
  font-family: neue-haas-grotesk-text, sans-serif; }

.summary table.variations label {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 1px;
  color: #888888; }

.summary table.variations .reset_variations {
  font-size: 12px;
  color: red; }

.vi-wpvs-variation-wrap-image .vi-wpvs-option-wrap {
  padding: 2px !important;
  border-radius: 0 !important;
  width: 70px !important;
  height: 70px !important; }

.coleccion {
  margin-bottom: 2rem; }
  .coleccion__item {
    display: grid;
    grid-template-columns: 60px 50px auto 70px;
    grid-gap: 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 4px; }
  .coleccion__foto img {
    width: 50px;
    height: auto;
    border-radius: 2px; }
  .coleccion__quantity label {
    display: none; }
  .coleccion__quantity .quantity input {
    width: 100%;
    padding: 0.75rem 0.5rem !important;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #595959;
    min-width: 50px; }
  .coleccion__nombre .precio-metro {
    font-size: 0.8rem;
    background-color: #1a191e;
    color: rgba(0, 0, 0, 0.6);
    padding: 1px 4px;
    border-radius: 3px; }
  .coleccion__label label {
    display: flex;
    margin-bottom: 3px; }
  .coleccion__label label a {
    font-size: 13px;
    font-weight: 400;
    color: #888888; }
  .coleccion__price {
    text-align: right;
    font-size: 1rem;
    color: #f8d3c3;
    font-weight: 700; }
    .coleccion__price .stock {
      margin: 0;
      font-size: 0.8rem;
      color: #595959; }

.garantia-extras {
  font-size: 0.8rem; }
  .garantia-extras a {
    color: #888888;
    border-bottom: 1px solid;
    font-weight: normal; }
  .garantia-extras__nav {
    list-style: none;
    padding-left: 0; }
  .garantia-extras__item {
    margin-bottom: 5px; }
    @media (max-width: 575px) {
      .garantia-extras__item {
        margin-bottom: 6px; } }
    .garantia-extras__item .bi {
      margin-right: 5px; }
    .garantia-extras__item--share a {
      border-bottom: 0;
      font-weight: bold;
      color: #128c7e; }
  .garantia-extras a.verde-whatsapp {
    color: #128c7e; }
    @media (max-width: 575px) {
      .garantia-extras a.verde-whatsapp {
        background-color: #128c7e;
        color: white;
        display: inline-block;
        border-radius: 50px;
        padding: 0 8px;
        text-decoration: none; } }

.garantia-sat {
  display: flex;
  align-items: center;
  background-color: rgba(222, 224, 125, 0.1);
  padding: 7px 10px;
  border-radius: 5px;
  border: 2px dashed #fdfdf9; }
  .garantia-sat:hover {
    text-decoration: none;
    border: 2px dashed #d4d654; }
  .garantia-sat__icon {
    flex: 0 0 40px;
    margin-right: 1rem; }
    .garantia-sat__icon img {
      width: 100%;
      height: auto; }
  .garantia-sat__content {
    color: #888888;
    font-family: neue-haas-grotesk-text, sans-serif;
    line-height: 1.4; }
    .garantia-sat__content strong {
      color: #c4c730; }

@media (max-width: 767px) {
  .woocommerce-tabs {
    margin-bottom: 3rem; } }

@media screen and (max-width: 575px) {
  .woocommerce-tabs {
    background-color: white;
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -11px;
    margin-right: -11px; } }

.woocommerce-tabs .wc-tab h2 {
  margin-top: 0;
  font-size: 1.5rem; }
  @media (max-width: 767px) {
    .woocommerce-tabs .wc-tab h2 {
      display: none; } }

.woocommerce-tabs .wc-tab {
  padding-top: 1rem;
  border-radius: 0.5rem; }
  @media screen and (max-width: 575px) {
    .woocommerce-tabs .wc-tab {
      padding-top: 1rem; } }
  .woocommerce-tabs .wc-tab > div {
    font-family: neue-haas-grotesk-text, sans-serif; }

.woocommerce-tabs .tabs {
  padding-left: 0;
  list-style: none;
  display: flex; }
  @media screen and (max-width: 575px) {
    .woocommerce-tabs .tabs {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.3rem; } }
  @media screen and (min-width: 576px) {
    .woocommerce-tabs .tabs li {
      margin-right: 0.3rem; } }
  .woocommerce-tabs .tabs li a {
    display: block;
    position: relative;
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.2);
    color: #888888;
    border-radius: 4px; }
    @media screen and (max-width: 575px) {
      .woocommerce-tabs .tabs li a {
        text-align: center; } }
    @media screen and (max-width: 380px) {
      .woocommerce-tabs .tabs li a {
        padding: 4px 6px !important;
        font-size: 0.75rem; } }
    .woocommerce-tabs .tabs li a:hover {
      background-color: #1a191e;
      color: white; }
  .woocommerce-tabs .tabs li.active a {
    color: white;
    background-color: #595959;
    position: relative; }

.comment-form-email label,
.comment-form-author label {
  display: block; }

.wp-comment-cookies-consent label {
  font-size: 0.7rem !important; }

.woocommerce-product-attributes {
  width: 100%; }
  @media screen and (max-width: 575px) {
    .woocommerce-product-attributes-item {
      font-size: 0.8rem; } }
  .woocommerce-product-attributes-item__label {
    background-color: #242328;
    width: 25%;
    text-align: right;
    padding-right: 1rem; }
    @media screen and (max-width: 575px) {
      .woocommerce-product-attributes-item__label {
        padding-right: 0;
        text-align: center; } }
  .woocommerce-product-attributes-item__value {
    padding-left: 1rem;
    background-color: rgba(0, 0, 0, 0.05); }
    @media screen and (max-width: 575px) {
      .woocommerce-product-attributes-item__value {
        padding-left: 0;
        text-align: center; } }
    .woocommerce-product-attributes-item__value p {
      margin: 0.5rem 0; }

.woocommerce-product-gallery {
  position: relative; }
  @media screen and (max-width: 767px) {
    .woocommerce-product-gallery {
      margin-bottom: 1rem; } }
  .woocommerce-product-gallery__trigger {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10; }
  @media screen and (min-width: 768px) {
    .woocommerce-product-gallery__wrapper {
      position: sticky;
      top: 75px; } }
  .woocommerce-product-gallery img {
    width: 100%;
    height: auto; }

.woocommerce-product-gallery__wrapper {
  margin-bottom: 1rem; }
  @media screen and (max-width: 575px) {
    .woocommerce-product-gallery__wrapper {
      margin-bottom: 0.5rem; } }
  .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image {
    width: auto;
    display: inline-flex;
    margin-right: 5px; }
    .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image a img {
      width: 90px;
      height: auto;
      border-radius: 4px; }
      @media (max-width: 767px) {
        .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image a img {
          width: 70px; } }
  .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image:first-child {
    width: 100%;
    display: block;
    margin-bottom: 5px; }
  .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image--placeholder {
    width: 100%;
    height: auto; }
  .woocommerce-product-gallery__wrapper .wp-post-image {
    width: 100% !important;
    height: auto;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2); }

.single-product.woocommerce div.product .images figure [data-thumb] {
  width: 100% !important;
  float: none !important; }

.x-container {
  display: flex;
  overflow-y: auto; }

.products.related > h2,
.products.upsells > h2 {
  margin-bottom: 1.2rem !important;
  font-size: 1.2rem; }

section.related ul.products {
  display: grid;
  grid-template-columns: 1fr 1fr; }
  section.related ul.products li.product {
    display: block;
    width: 100%;
    height: auto;
    clear: both; }
  section.related ul.products .attachment-woocommerce_thumbnail {
    float: left !important;
    width: 100% !important;
    height: auto;
    margin-right: 1rem; }
  section.related ul.products .woocommerce-loop-product__link {
    width: 100%;
    align-items: inherit;
    display: block !important;
    height: auto;
    overflow: hidden; }
  section.related ul.products .woocommerce-loop-product__title {
    font-size: 0.9rem;
    width: auto;
    color: #595959; }

.garantia {
  display: flex;
  gap: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 1rem; }
  .garantia__item {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    flex: 1 0 30%; }
  .garantia__ico {
    margin-right: 10px; }
    .garantia__ico svg {
      width: 25px;
      height: 25px; }
    .garantia__ico .bi {
      font-size: 2rem;
      color: #595959; }

#review_form .comment-reply-title {
  font-size: 0.7rem;
  margin-bottom: 1rem;
  display: block; }

.product .star-rating span:before,
.star-rating::before {
  color: #f8d3c3 !important;
  border-color: #f8d3c3 !important; }

.woocommerce-product-rating .woocommerce-review-link {
  font-size: 0.7rem;
  color: #888888; }

.product {
  position: relative; }
  .product .onsale {
    background-color: #e9eab6;
    display: inline-block;
    position: absolute;
    left: 1rem;
    top: 1rem;
    z-index: 10;
    font-size: 0.8rem;
    padding: 5px 10px;
    border-radius: 50px;
    color: white; }

.voucher-fields-wrapper label {
  display: block;
  width: 100%;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-left: 0rem; }

.voucher-fields-wrapper input,
.voucher-fields-wrapper textarea {
  width: 100%;
  border-radius: 5px; }

/**
	 * Star ratings
	 */
.star-rating {
  float: right;
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: 1;
  font-size: 1em;
  width: 5.4em;
  font-family: "WooCommerce"; }
  .star-rating::before {
    content: "\e020\e020\e020\e020\e020";
    float: left;
    top: 0;
    left: 0;
    position: absolute; }
  .star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em; }
  .star-rating span::before {
    content: "\e021\e021\e021\e021\e021";
    top: 0;
    position: absolute;
    left: 0; }

.woocommerce-product-rating {
  line-height: 2;
  display: block; }
  .woocommerce-product-rating .star-rating {
    margin: 0.5em 4px 0 0;
    float: left; }

.products .star-rating {
  display: block;
  margin: 0 0 0.5em;
  float: none; }

.hreview-aggregate .star-rating {
  margin: 10px 0 0; }

#review_form #respond {
  position: static;
  margin: 0;
  width: auto;
  padding: 0;
  background: transparent none;
  border: 0; }
  #review_form #respond p {
    margin: 0 0 10px; }
  #review_form #respond .form-submit input {
    left: auto; }
  #review_form #respond textarea {
    box-sizing: border-box;
    width: 100%; }

p.stars a {
  position: relative;
  height: 1em;
  width: 1em;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none; }
  p.stars a::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    line-height: 1;
    font-family: "WooCommerce";
    content: "\e021";
    text-indent: 0; }
  p.stars a:hover ~ a::before {
    content: "\e021"; }

p.stars:hover a::before {
  content: "\e020"; }

p.stars.selected a.active::before {
  content: "\e020"; }

p.stars.selected a.active ~ a::before {
  content: "\e021"; }

p.stars.selected a:not(.active)::before {
  content: "\e020"; }

.commentlist {
  list-style: none;
  padding-left: 0; }
  .commentlist .comment_container {
    width: 100%;
    display: flex;
    gap: 1rem;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 5px;
    margin-bottom: 5px !important; }
    .commentlist .comment_container .avatar {
      width: 40px;
      height: 40px;
      display: none; }
    .commentlist .comment_container .comment-text {
      width: 100%; }
      .commentlist .comment_container .comment-text .star-rating {
        float: none; }
      .commentlist .comment_container .comment-text .meta {
        margin-top: .3rem;
        font-size: 1rem;
        margin-bottom: .3rem !important; }
      .commentlist .comment_container .comment-text .description p {
        margin-top: 0; }
