.cuadro-medico {
  &__encabezado {
    max-width: 50%;
    @media screen and (max-width: 991px) {
      max-width: 100%;
    }
  }

  &__titulo {
  }

  &__subtitulo {
    max-width: 100%;
  }

  &__content {
  }

  &__listado {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem 6rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem 4rem;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: 3rem;
    }
  }

  &__especialista {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__img {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  &__nombre {
    margin: 0.5rem 0;
    font-size: 1.875rem;
    color: $color-texto !important;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__especialidad {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    font-size: 1rem;
    border: 1px solid $color-secundario;
    background-color: $color-secundario;
    color: $color-texto;
    padding: 2px 7px;
    border-radius: 0rem;
    font-weight: 600;
    a {
      color: $color-texto;
      text-decoration: none;
    }
    @media screen and (max-width: 575px) {
      padding: 0.2rem 0.5rem;
      font-size: 0.9rem;
    }
  }

  &__numero {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    font-size: 1rem;
    color: $color-gris-04;
    font-weight: 600;
    a {
      color: $color-texto;
      text-decoration: none;
    }
    @media screen and (max-width: 575px) {
      font-size: 0.9rem;
    }
  }
}

.page-id-918 .cuadro-medico__especialidad {
  border: 1px solid $color-principal;
  background-color: $color-principal;
}

.page-id-919 .cuadro-medico__especialidad {
  border: 1px solid $color-alternativo;
  background-color: $color-alternativo;
}
