@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin input-buttons {
  padding: 0.625rem 1.875rem;
  border: 2px;
  border-radius: 5px;
  letter-spacing: 1px;
  font-weight: 500;
  cursor: pointer;
  transition: $trans;
  display: inline-flex;
  align-items: center;
  @media (max-width: 575px) {
    padding: 0.75rem 1.2rem;
  }
  @media (max-width: 390px) {
    padding: 0.7rem 1rem;
    font-size: 0.8rem;
  }
  .bi {
    margin-left: 5px;
  }
}

@mixin input-buttons-lg {
  padding: 1.3rem 1.5rem;
  border: 2px;
  letter-spacing: 1px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: $trans;
}

@mixin input-buttons-sm {
  padding: 0.55rem 1.2rem;
  letter-spacing: 0px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: $trans;
}

@mixin input-buttons-hover {
  // transform: translateY(-2px);
  box-shadow: $box-shadow-hover;
  transition: $trans;
}

@mixin cart-count($top, $right, $size) {
  display: inline-block;
  position: absolute;
  background-color: $color-secundario;
  padding: 0px 2px;
  border-radius: 3px;
  text-align: center;
  right: $right;
  top: $top;
  color: #fff;
  font-size: $size;
}

@mixin card-yellow($padding-card) {
  background-color: rgba($color: $color-alternativo, $alpha: 0.1);
  padding: $padding-card;
  border-radius: 5px;
  border: 2px dashed lighten($color-alternativo, 30%);
}
