.single-servicio {
  &__content {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 10rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: 6rem;
    }
  }

  &__left {
    font-size: 1.25rem;
    h2 {
      font-size: 2.5rem;
    }
  }

  &__right {
  }

  &__img {
    width: 100%;
    height: auto;
    margin-bottom: 2.8rem;
  }

  &__relacionados {
  }
}

.relacionados {
  &__listado {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    font-size: 1rem;
    border: 1px solid $color-principal;
    background-color: $color-principal;
    color: $color-texto;
    padding: 0.75rem 2rem;
    border-radius: 0rem;
    box-shadow: $box-shadow-02;
    a {
      color: $color-texto;
      text-decoration: none;
    }
    @media screen and (max-width: 575px) {
      padding: 0.2rem 0.5rem;
      font-size: 0.8rem;
    }
  }
}
