.carrito-side {
  padding: 1rem;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  width: 20rem;
  height: 100%;
  opacity: 0;
  background-color: $color-background;
  transition: $trans;
  &.activo {
    opacity: 1;
    transition: $trans;
  }

  &__container {
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
  }
  &__cerrar {
    text-align: right;
    a {
      color: $color-gris-dark;

      &:hover {
        color: $color-principal;
      }
    }
  }
}
