.servicios-medicos {
  &__content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 3rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  &__left {
    @media screen and (max-width: 991px) {
      padding-bottom: 7rem;
    }
    @media screen and (max-width: 575px) {
      padding-bottom: 3rem;
    }
  }

  &__titulo {
  }

  &__subtitulo {
    font-family: "karmina-sans", sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
  }

  &__right {
  }

  &__listado {
    .accordion-button {
      background-color: $color-secundario;
      border-radius: 0.5rem !important;
      transition: $trans-3;
    }
    .accordion-button:not(.collapsed) {
      color: $color-texto;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      transition: $trans-3;
      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3e%3c/svg%3e");
      }
    }
    .accordion-button::after {
      width: 2rem;
      height: 2rem;
      background-size: 2rem;
      background-color: $color-blanco;
      border-radius: 16px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3e%3c/svg%3e");
    }
    .accordion-button:focus {
      box-shadow: none;
    }
  }

  &__servicio {
    margin-bottom: 2rem;
    border: none;
    box-shadow: $box-shadow-02;
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  &__nombre {
    button {
      font-size: 1.5625rem;
    }
  }

  &__descripcion {
    font-size: 1.25rem;
    padding: 2rem;
    p {
      margin: 0;
    }
  }
}
