.commentlist {
  list-style: none;
  padding-left: 0;
  .comment_container {
    width: 100%;
    display: flex;
    gap: 1rem;
    padding: 10px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    background-color: rgba($color: #000000, $alpha: 0.02);
    border-radius: 5px;
    margin-bottom: 5px !important;
    .avatar {
      width: 40px;
      height: 40px;
      display: none;
    }
    .comment-text {
      width: 100%;
      .star-rating {
        float: none;
      }
      .meta {
        margin-top: .3rem;
        font-size: $font-size;
        margin-bottom: .3rem !important;
      }
      .description {
        p {
          margin-top: 0;
        }
      }
    }
  }
}