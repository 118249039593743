$color-principal: #ecdbed;
$color-principal: #e9e0ea;
$color-principal-dark: #794a7b;
$color-secundario: #e9eab6;
$color-secundario-dark: #4a4a24;
$color-alternativo: #dee07d;

$color-principal: #f8d3c3;
//$color-principal-dark: #8e6857;
$color-principal-dark: #f88379;

$color-gris-lighter: rgba(0, 0, 0, 0.05);
$color-gris-light: rgba(0, 0, 0, 0.15);
$color-gris-med: rgba(0, 0, 0, 0.25);
$color-gris: rgba(0, 0, 0, 0.5);
$color-gris-dark: rgba(0, 0, 0, 0.6);
$color-gris-darker: rgba(0, 0, 0, 0.8);

$color-gris-01: #242328;
$color-gris-02: #1a191e;
$color-gris-03: #595959;
$color-gris-04: #888888;
$color-gris-05: #717173;

$color-gris-06: #e1e1e1;

$color-whatsapp: #128c7e;

$color-overlay: rgba(0, 0, 0, 0.65);

$color-background: rgba(255, 255, 255, 1);
$color-blanco: rgba(255, 255, 255, 1);
$color-negro: rgba(0, 0, 0, 1);

$color-texto: #3c3c3b;

$font-size: 1rem;
$font-size-small: 0.8rem;
$font-size-smaller: 0.7rem;

$font-weight: 400;

$box-shadow-02: 0 0 30px 0 rgba(0, 0, 0, 0.07);
$box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
$box-shadow-hover: 0 0 20px 0 rgba(0, 0, 0, 0.05);

$border-radius: $font-size / 2;

$trans: 0.2s all ease-in-out;
$trans-3: 0.3s all ease-in-out;
$trans-5: 0.5s all ease-in-out;
$trans-4: 1s all ease-in-out;

$sp-05: 1.5rem;
$sp-1: 3rem;
$sp-2: 7rem;
$sp-3: 12rem;

$typo-principal: "karmina-sans", sans-serif;
$typo-secundaria: "karmina", serif;
$typo-alternativa: neue-haas-grotesk-text, sans-serif;
