.page-title {
  position: relative;
  padding-top: 10rem;
  padding-bottom: 3.75rem;
  background-color: $color-alternativo;
  @media screen and (max-width: 575px) {
    padding-top: 7rem;
    padding-bottom: 2rem;
  }

  &__content {
    position: relative;
    color: $color-texto;
  }

  &__breadcrumbs {
    color: $color-texto;
    font-size: 0.8rem;
    text-transform: uppercase;
    a {
      color: $color-texto;
      font-weight: bold;
      text-decoration: none;
    }
  }

  &__header-01 {
    font-weight: bold;
    font-size: 4.0625rem;
    margin-top: 0;
    span {
      color: $color-blanco;
    }
    @media screen and (max-width: 991px) {
      font-size: 3.6rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 2.4rem;
    }
  }

  &__header-03 {
    font-weight: bold;
    font-size: 2.185rem;
    margin-bottom: 0;
    p {
      margin-bottom: 0.5rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.8rem;
    }
  }
}

// .page-template-page-servicios-medicos .page-title,
// .page-template-page-cuadro-medico .page-title,
// .single-medico .page-title,
// .page-template-page-cuadro-medico .page-title,
// .page-template-page-seguros .page-title {
//   background-color: $color-secundario;
// }

// .page-template-page-contacto .page-title,
// .blog .page-title,
// .single-post .page-title,
// .page-id-919 .page-title,
// .page-id-1109 .page-title,
// .page-id-1106 .page-title {
//   background-color: $color-alternativo;
// }

.page-template-page-estetica-cirugia .page-title,
.page-template-page-centro-estetico .page-title,
.page-id-918 .page-title,
.single-servicio .page-title,
.page-template-page-citas .page-title,
.page-template-page-cuadro-estetico .page-title,
.woocommerce-page .page-title {
  background-color: $color-principal;
}

.page-id-1033 .page-title,
.page-id-13 .page-title {
  background-color: $color-alternativo;
}
