.claim {
  &__container {
    padding: 4rem 0;
    display: flex;
    justify-content: center;
  }

  &__content {
    text-align: center;
  }

  &__title {
  }

  &__subtitle {
    font-size: 1rem;
    @media screen and (max-width: 767px) {
      line-height: 1.4;
    }
    mark {
      background-color: $color-alternativo;
      padding-left: 3px;
      padding-right: 2px;
      margin-left: 2px;
      margin-right: 3px;
      border-radius: 2px;
      @media screen and (max-width: 767px) {
        padding-top: 1px;
        padding-bottom: 1px;
      }
    }
  }
}

.estetica {
  margin-top: 2px;
  background-color: $color-principal;
  &__content {
  }

  &__col1 {
  }

  &__pretitulo {
    color: $color-principal-dark;
  }

  &__titulo {
  }

  &__descripcion {
    margin-bottom: 1rem !important;
    font-size: 2.1875rem;
    color: $color-blanco;
    line-height: 1.3;
    margin-bottom: 2.75rem;
    font-weight: 600;
    @media screen and (max-width: 991px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.3rem;
      margin: 1rem 0 2rem 0;
    }
  }

  &__botones {
  }

  &__col2 {
  }

  &__thumb {
  }

  &__imagen {
  }
}

.medico {
  margin-top: 2px;
  background-color: $color-secundario;
  &__content {
  }

  &__col1 {
  }

  &__pretitulo {
    color: $color-secundario-dark;
  }

  &__titulo {
  }

  &__descripcion {
    margin-bottom: 1rem !important;
    font-size: 2.1875rem;
    color: $color-blanco;
    line-height: 1.3;
    margin-bottom: 2.75rem;
    font-weight: 600;
    @media screen and (max-width: 991px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.3rem;
      margin: 1rem 0 2rem 0;
    }
  }

  &__botones {
  }

  &__col2 {
  }

  &__thumb {
  }

  &__imagen {
  }
}

.tratamientos {
  background-color: $color-principal;
  .owl-stage {
    padding: 2rem 0;
    @media screen and (max-width: 575px) {
      padding: 1rem 0;
    }
    .owl-item {
      box-shadow: $box-shadow;
      border-radius: 16px;
    }
  }
  &__subtitulo {
    max-width: 45%;
  }

  &__listado {
  }

  &__item {
    position: relative;
    height: 300px;
    width: auto;
    border-radius: 16px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 991px) {
      height: 200px;
    }
    @media screen and (max-width: 575px) {
      height: 164px;
    }
  }

  &__enlace {
    position: revert;
    color: $color-blanco;
  }

  &__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    //transition: $trans;
    background-color: $color-gris-med;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: $trans-3;
  }

  &__info {
    position: absolute;
  }

  &__nombre {
    color: $color-blanco;
    font-size: 1.3125rem;
    font-weight: bold;
    letter-spacing: 2.1px;
    text-transform: uppercase;
    &:hover {
      color: $color-blanco;
    }
    @media screen and (max-width: 991px) {
      text-align: center;
      letter-spacing: 0;
    }
  }

  &__img {
    border-radius: 16px;
    position: relative;
    width: 100%;
    height: auto;
  }
}

.tratamientos__item:hover .tratamientos__overlay {
  background-color: $color-gris-dark;
  transition: $trans-3;
}

.seguros {
  background-color: $color-secundario;
  .owl-stage {
    padding: 2rem 0;
    @media screen and (max-width: 575px) {
      padding: 1rem 0;
    }
    .owl-item {
      width: 180px;
      box-shadow: $box-shadow;
      background-color: $color-background;
      border-radius: 16px;
    }
  }
  &__titulo {
  }

  &__subtitulo {
    max-width: 45%;
  }

  &__listado {
  }
  &__item {
    width: 100%;
    // height: 116px;
    // overflow: hidden;
    // @media screen and (max-width: 991px) {
    //   height: 112px;
    // }

    overflow: hidden;
    img {
      width: 73% !important;
      max-width: 73% !important;
      max-height: 50% !important;
      margin: 0 auto;
    }
  }
}

.instalaciones {
  background-color: $color-secundario;
  .owl-stage {
    padding: 2rem 0;
    @media screen and (max-width: 575px) {
      padding: 1rem 0;
    }
    .owl-item {
      box-shadow: $box-shadow;
      border-radius: 16px;
    }
  }
  &__titulo {
  }

  &__subtitulo {
    max-width: 45%;
  }
  &__item {
    position: relative;
    height: 300px;
    width: 100%;
    border-radius: 16px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    @media screen and (max-width: 991px) {
      height: 200px;
    }
    @media screen and (max-width: 575px) {
      height: 164px;
    }
  }

  &__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    //transition: $trans;
    background-color: $color-gris-med;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: $trans-3;
  }

  &__info {
    position: absolute;
    color: $color-blanco;
  }

  &__nombre {
    color: $color-blanco;
    font-size: 1.3125rem;
    font-weight: bold;
    letter-spacing: 2.1px;
    text-transform: uppercase;
    &:hover {
      color: $color-blanco;
    }
  }

  &__img {
    border-radius: 16px;
    position: relative;
    width: 100%;
    height: auto;
  }
}

.instalaciones__item:hover .instalaciones__overlay {
  background-color: $color-gris-dark;
  transition: $trans-3;
}

.bloque {
  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 6fr 5fr;
      gap: 3rem;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__col1 {
  }

  &__pretitulo {
  }

  &__titulo {
    font-size: 3.0625rem;
    line-height: 1.2;
    font-family: $typo-secundaria;
    font-weight: 600;
    @media screen and (max-width: 991px) {
      font-size: 2.8rem;
    }
  }

  &__subtitulo {
    font-size: 1.375rem;
    line-height: 1.2;
    max-width: 45%;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-smooth: antialiased;
    @media screen and (max-width: 991px) {
      max-width: 100%;
    }
  }

  &__descripcion {
    font-size: 2.1875rem;
    color: $color-blanco;
    line-height: 1.3;
    margin: 2rem 0 1rem 0;
    font-weight: 600;
    @media screen and (max-width: 991px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.875rem;
      margin: 1rem 0 2rem 0;
    }
  }

  &__logo {
    width: 170px;
    height: auto;
    margin-bottom: 3rem;
  }

  &__botones {
    .boton {
      font-weight: 600;
    }
    .boton.ms-2 {
      @media screen and (max-width: 575px) {
        margin-left: 0 !important;
        margin-top: 1rem;
      }
    }
  }

  &__col2 {
  }

  &__thumb {
  }

  &__imagen {
    width: 100%;
    height: auto;
    border-radius: $border-radius;
  }
}
