.p-seguros {
  &__content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 3rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
  }

  &__nombre {
  }
  &__img {
    width: 100%;
    height: 156px;
    object-fit: cover;
    @media screen and (max-width: 991px) {
      height: 106px;
    }
    @media screen and (max-width: 575px) {
      height: 96px;
    }
  }
}
