.centro-estetico {
  @media screen and (max-width: 991px) {
    padding-bottom: 0rem;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 5rem;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }

  &__left {
  }

  &__descripcion {
    font-family: "karmina-sans", sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
    margin-bottom: 3rem;
  }

  &__right {
  }

  &__imagen-principal {
    img {
      width: 100%;
      height: auto;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      // @media screen and (max-width: 991px) {
      //   margin-bottom: 0.5rem;
      // }
    }
  }
}
.galeria {
  @media screen and (max-width: 991px) {
    padding-top: 0.5rem;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    @media screen and (max-width: 991px) {
      gap: 0.5rem;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
  }

  &__img {
    width: 100%;
    height: auto;
    box-shadow: $box-shadow-02;
    border-radius: $border-radius;
  }
}
